import { ThemeOptions } from '@material-ui/core';

/**
 * Конфигурация палитры цветов.
 */
const Palette: ThemeOptions = {
  palette: {
    action: {
      active: '#EEF2F7',
      disabled: '#BDBDBD',
      disabledBackground: '#E0E0E0',
      hover: '#F5F5F5',
      selected: '#EBEBEB',
    },

    background: {
      default: '#F4F5F7',
      paper: '#FFF',
    },

    border: '#DCDFE4',

    divider: '#E0E0E0',

    error: {
      contrastText: '#FFF',
      dark: '#C63D23',
      darkText: '#621B16',
      light: '#F88078',
      lightBackground: '#FEECEB',
      main: '#F44336',
    },

    info: {
      contrastText: '#FFF',
      dark: '#0A7AD0',
      darkText: '#0D3B61',
      light: '#64B6F7',
      lightBackground: '#E9F4FE',
      main: '#2196F3',
    },

    primary: {
      background: '#E6EAFA',
      border: '#AAB1E8',
      contrastText: '#FFF',
      dark: '#3C4693',
      light: '#7783DB',
      main: '#5664D2',
    },

    secondary: {
      background: '#FBDCE7',
      border: '#F48EB1',
      contrastText: '#FFF',
      dark: '#BE134D',
      light: '#EF6191',
      main: '#E91E63',
    },

    success: {
      contrastText: '#FFF',
      dark: '#3B873D',
      darkText: '#1E4620',
      light: '#7BC67E',
      lightBackground: '#EDF7ED',
      main: '#4CAF4F',
    },

    text: {
      disabled: '#9E9E9E',
      primary: '#253858',
      secondary: '#5C6A82',
    },

    warning: {
      contrastText: '#211401',
      dark: '#C77715',
      darkText: '#663D06',
      light: '#FEB547',
      lightBackground: '#FFF5E5',
      main: '#FE991E',
    },
  },
};

export default Palette;
