import { SimplePaletteColorOptions, ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

const primary = Palette.palette?.primary as SimplePaletteColorOptions;
const secondary = Palette.palette?.secondary as SimplePaletteColorOptions;

/**
 * Стилизация чекбокса.
 */
const MuiCheckbox: ThemeOptions = {
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: primary.background,
          },
        },

        '&$disabled': {
          color: Palette.palette?.text?.disabled,
        },
      },

      colorSecondary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: secondary.background,
          },
        },

        '&$disabled': {
          color: Palette.palette?.text?.disabled,
        },
      },
    },
  },
};

export default MuiCheckbox;
