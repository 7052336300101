import { ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

/**
 * Стилизация метки поля ввода FormLabel.
 */
const MuiInputLabel: ThemeOptions = {
  overrides: {
    MuiInputLabel: {
      disabled: {},

      filled: {
        '&$focused': {
          color: Palette.palette?.text?.secondary,
        },

        '&$root$error': {
          color: Palette.palette?.text?.secondary,
        },

        '&$marginDense': {
          transform: 'translate(12px, 10px) scale(1)',
        },

        '&$shrink': {
          transform: 'translate(12px, 10px) scale(0.8)',
        },
      },

      root: {
        fontSize: '0.9375rem',
        lineHeight: 1.167,

        '&$disabled': {
          color: Palette.palette?.text?.secondary,
          opacity: 0.5,
        },
      },

      shrink: {},
    },
  },
};

export default MuiInputLabel;
