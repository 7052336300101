import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENV,
  
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,

    beforeSend: (event, hint) => {
      const error = hint && hint.originalException;
  
      if (typeof error === "object" && error !== null) {
        event.extra = {
          ...event.extra,
          custom: error,
        };
      }
  
      return event;
    },
  });
}
