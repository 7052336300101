import { FC } from 'react';
import { Snackbar, Slide, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';

import Link from 'components/ui/Link';
import { ToastType } from 'types/Toast';
import useToasts from 'hooks/useToasts';

/**
 * Время видимости всплывающего сообщения.
 * @TODO: Вынести в конфиг(нет).
 */
const TOAST_VISIBLE_DURATION = 6000;

/**
 * Возвращает коллекцию классов элементов.
 */
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'underline',
  },

  success: {
    color: theme.palette.success.darkText,
  },

  error: {
    color: theme.palette.error.darkText,
  },

  info: {
    color: theme.palette.info.darkText,
  },
}));

/**
 * Отображает всплывающие уведомления.
 */
const Toasts: FC = () => {
  const styles = useStyles();
  const toasts = useToasts();

  const content = toasts.items.map((toast) => {
    function handleExit() {
      toasts.drop(toast.id);
    }

    function handleClose() {
      toasts.hide(toast.id);
    }

    const getCssClass = () => {
      switch (toast.type) {
        case ToastType.Success:
          return styles.success;

        case ToastType.Error:
          return styles.error;

        default:
          return styles.info;
      }
    };

    return (
      <Snackbar
        TransitionProps={{ onExited: handleExit }}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        autoHideDuration={TOAST_VISIBLE_DURATION}
        open={toast.open}
        key={toast.id}
      >
        <Alert
          severity={toast.type}
          onClose={handleClose}
          elevation={8}
          className={getCssClass()}
        >
          {toast.text}
          {toast.moreLink && (
            <>
              {'. '}
              <Link
                href={toast.moreLink}
                className={classNames(styles.link, getCssClass())}
              >
                Подробнее
              </Link>
            </>
          )}
        </Alert>
      </Snackbar>
    );
  });

  return <>{content}</>;
};

export default Toasts;
