/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import StorageKey from 'constants/StorageKey';
import { Component, createContext } from 'react';

/**
 * Значение контекста уведомлений.
 */
type NotificationsContextValue = Readonly<{
  /**
   * Коллекция идентификаторов "просмотренных" уведомлений.
   */
  items: string[];

  /**
   * Возвращает является ли задача "просмотренной".
   * @param taskID Идентификатор задачи.
   */
  isRead(taskID: string): boolean;

  /**
   * Помечает задачу как "просмотренную".
   * @param taskID Идентификатор задачи.
   */
  markRead(taskID: string): void;
}>;

/**
 * Контекст контейнера всплывающих сообщений.
 */
const NotificationsContext = createContext<NotificationsContextValue>(
  undefined! as NotificationsContextValue,
);

/**
 * Возвращает провайдер контекста контейнера уведомлений.
 */
export class NotificationsContextProvider extends Component<
  {},
  NotificationsContextValue
> {
  constructor(props: {}) {
    super(props);

    let items: string[] = [];

    try {
      const storageValue = localStorage.getItem(StorageKey.VIEWED_TASKS);

      if (storageValue) {
        const parsedValue = JSON.parse(storageValue);
        const inboundValue: string[] =
          Array.isArray(parsedValue) &&
          parsedValue.every((value) => typeof value === 'string')
            ? parsedValue
            : [];

        items = inboundValue;
      }
    } catch {
      /* Silently suppress errors */
    }

    this.state = {
      items,
      isRead: this.#isRead,
      markRead: this.#markRead,
    };
  }

  #isRead = (taskID: string) => this.state.items.includes(taskID);

  #markRead = (taskID: string) =>
    this.setState((old) => {
      const newItems = [...old.items.filter((item) => item !== taskID), taskID];

      localStorage.setItem(
        StorageKey.VIEWED_TASKS,
        JSON.stringify(newItems.slice(-10)),
      );

      return {
        items: newItems,
      };
    });

  render() {
    return (
      <NotificationsContext.Provider value={this.state}>
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}

export default NotificationsContext;
