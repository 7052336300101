import { ThemeOptions } from '@material-ui/core';
import { Palette as PaletteType } from '@material-ui/core/styles/createPalette';

import Palette from './Palette';

const palette = Palette.palette as PaletteType;

/**
 * Стилизация ячейки таблицы
 */

const MuiTableCell: ThemeOptions = {
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${palette.divider}`,
        lineHeight: 1.214,

        '&:first-child': {
          paddingLeft: 24,
        },

        '&:last-child': {
          paddingRight: 24,
        },
      },

      head: {
        lineHeight: 1.214,
      },
    },
  },
};

export default MuiTableCell;
