/**
 * Текст ошибки поля ввода на форме.
 */
enum FieldErrorText {
  /**
   * Отображается при незаполненом поле.
   */
  EMPTY = 'Поле должно быть заполнено',

  /**
   * Отображается при незаполненом имени пользователя.
   */
  EMPTY_USERNAME = 'Введите имя пользователя',

  /**
   * Отображается при незаполненном пароле.
   */
  EMPTY_PASSWORD = 'Введите пароль',

  /**
   * Отображается при незаполненном коде подтверждения.
   */
  EMPTY_CODE = 'Введите код подтверждения',

  /**
   * Отображается при незаполненном номере телефона.
   */
  EMPTY_PHONE = 'Введите номер телефона',

  /**
   * Отображается при незаполненном новом пароле.
   */
  EMPTY_NEW_PASSWORD = 'Введите новый пароль',

  /**
   * Отображается при незаполненом поле "повторите пароль".
   */
  EMPTY_REPEAT_PASSWORD = 'Повторите новый пароль',

  /**
   * Отображается, если номер телефона имеет неверный формат.
   */
  BAD_PHONE_FORMAT = 'Неверный формат номера телефона',

  /**
   * Отображается, если код подтверждения имеет неверный формат.
   */
  BAD_CODE_FORMAT = 'Неверный формат кода подтверждения',

  /**
   * Отображается, если новый пароль и его повтор не совпадают.
   */
  PASSWORD_MISMATCH = 'Введенные пароли не совпадают',

  /**
   * Отображается, когда при входе пользователь ввёл неверные имя
   * пользователя или пароль.
   */
  BAD_LOGIN_CREDENTIALS = 'Неверное имя пользователя или пароль',

  /**
   * Отображается, когда пользователь ввёл неверный код подтверждения.
   */
  BAD_CODE = 'Неверный код подтверждения',

  /**
   * Отображается, когда от пользователя требуется ввести мобильный телефон, на
   * который можно отправить СМС.
   */
  NOT_MOBILE_PHONE = 'Номер телефона должен начинаться с девятки',

  /**
   * Отображается, когда пользователь неверно вводит дату.
   */
  BAD_DATE_FORMAT = 'Неверный формат даты',

  /**
   * Отображается, когда пользователь ввёл синтаксически корректную, но
   * семантически неверную дату, к примеру, '31.02.1991'.
   */
  BAD_DATE_PARAMS = 'Такой даты не существует',

  /**
   * Отображается, когда пользователь пытается выбрать дату в будущем.
   */
  FUTURE_DATE = 'Нельзя использовать будущую дату',

  /**
   * Отображается, когда нарушена последовательность дат.
   */
  BAD_DATE_ORDER = 'Дата "До" не может быть раньше "От"',
}

export default FieldErrorText;
