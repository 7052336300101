import { ThemeOptions } from '@material-ui/core';

/**
 * Конфигурация скруглений углов элементов.
 */
const Shape: ThemeOptions = {
  shape: {
    borderRadius: 8,
  },
};

export default Shape;
