import { ThemeOptions } from '@material-ui/core';

/**
 * Конфигурация шрифтов.
 */
const Typography: ThemeOptions = {
  typography: {
    body1: {
      fontSize: 14,
      lineHeight: 1.214,
    },

    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),

    h4: {
      fontWeight: 600,
      fontSize: 27,
    },

    subtitle1: {
      fontSize: 12,
    },
  },
};

export default Typography;
