import { ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

/**
 * Стилизация селекта.
 */
const MuiSelect: ThemeOptions = {
  overrides: {
    MuiSelect: {
      filled: {
        '&$filled': {
          paddingRight: 48,
        },

        '&$filled.MuiFilledInput-inputMarginDense': {
          paddingRight: 40,
        },
      },

      icon: {
        color: Palette.palette?.text?.primary,
      },

      iconFilled: {
        right: 12,
      },
    },
  },
};

export default MuiSelect;
