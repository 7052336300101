import { ThemeOptions } from '@material-ui/core';

/**
 * Конфигурация тулбара.
 */
const MuiToolbar: ThemeOptions = {
  overrides: {
    MuiToolbar: {
      gutters: {
        paddingRight: 48,
        paddingLeft: 48,

        '@media (min-width: 600px)': {
          paddingRight: 48,
          paddingLeft: 48,
        },
      },
    },
  },
};

export default MuiToolbar;
