import { ThemeOptions } from '@material-ui/core';

/**
 * Стилизация Adornment для полей ввода.
 */

const MuiInputAdornment: ThemeOptions = {
  overrides: {
    MuiInputAdornment: {
      filled: {
        '&$positionEnd:not($hiddenLabel)': {
          marginTop: 0,
        },

        '&$positionStart:not($hiddenLabel)': {
          marginTop: 0,
        },
      },

      hiddenLabel: {},

      positionEnd: {
        marginLeft: 12,
      },

      positionStart: {
        marginRight: 12,
      },
    },
  },
};

export default MuiInputAdornment;
