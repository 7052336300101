/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import { Component, createContext } from 'react';
import { Toast, ToastText, ToastType } from 'types/Toast';

/**
 * Значение контекста всплывающих сообщений.
 */
type ToastsContextValue = Readonly<{
  /**
   * Коллекция всплывающих уведомлений.
   */
  readonly items: Readonly<Toast>[];

  /**
   * Показывает вслывающее уведомление об успехе операции с указанным текстом.
   * @param text Текст.
   * @param moreLink Ссылка на полную информацию.
   */
  success(text: ToastText, moreLink?: string): void;

  /**
   * Показывает всплывающее уведомление об ошибке с указанным текстом.
   * @param text Текст.
   * @param moreLink Ссылка на полную информацию.
   */
  error(text: ToastText, moreLink?: string): void;

  /**
   * Запускает процесс сокрытия уведомления.
   * @param toastID Идентификатор уведомления.
   */
  hide(toastID: string): void;

  /**
   * Удаляет уведомление.
   * @param toastID Идентификатор уведомления.
   */
  drop(toastID: string): void;
}>;

/**
 * Контекст контейнера всплывающих сообщений.
 */
const ToastsContext = createContext<ToastsContextValue>(
  undefined! as ToastsContextValue,
);

/**
 * Возвращает провайдер контекста контейнера всплывающих сообщений.
 */
export class ToastsContextProvider extends Component<{}, ToastsContextValue> {
  /**
   * Последний использовавшийся идентификатор всплывающего сообщения.
   */
  #lastID = 0;

  constructor(props: {}) {
    super(props);

    this.state = {
      items: [],
      success: this.#success,
      error: this.#error,
      hide: this.#hide,
      drop: this.#drop,
    };
  }

  /**
   * Показывает описанное уведомление.
   * @param type Тип уведомления.
   * @param text Текст уведомления.
   * @param moreLink Ссылка на полную информацию.
   */
  #show = (type: ToastType, text: ToastText, moreLink?: string) => {
    this.setState((oldState) => ({
      items: [
        ...oldState.items,
        {
          // eslint-disable-next-line no-plusplus
          id: String(this.#lastID++),
          type,
          text,
          moreLink,
          open: true,
        },
      ],
    }));
  };

  #success = (text: ToastText, moreLink?: string) => {
    this.#show(ToastType.Success, text, moreLink);
  };

  #error = (text: ToastText, moreLink?: string) => {
    this.#show(ToastType.Error, text, moreLink);
  };

  #hide = (toastID: string) => {
    this.setState((oldState) => {
      const index = oldState.items.findIndex((toast) => toast.id === toastID);

      if (index === -1) {
        return oldState;
      }

      const toast = oldState.items[index];

      return {
        items: [
          ...oldState.items.slice(0, index),
          {
            ...toast,
            open: false,
          },
          ...oldState.items.slice(index + 1),
        ],
      };
    });
  };

  #drop = (toastID: string) => {
    this.setState((oldState) => ({
      items: oldState.items.filter((toast) => toast.id !== toastID),
    }));
  };

  render() {
    return (
      <ToastsContext.Provider value={this.state}>
        {this.props.children}
      </ToastsContext.Provider>
    );
  }
}

export default ToastsContext;
