import { ThemeOptions } from '@material-ui/core';

/**
 * Задаёт глобальные стили.
 */
const MuiCssBaseline: ThemeOptions = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },

        body: {
          letterSpacing: 'normal',

          display: 'flex',
          flexDirection: 'column',
          lineHeight: 1.214,
          minHeight: '100%',
        },

        '#__next': {
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
        },
      },
    },
  },
};

export default MuiCssBaseline;
