/**
 * Настройки для определения сложности пароля.
 */
const PasswordStrengthConfig = {
  /**
   * Минимальная длина пароля.
   */
  MIN_LENGTH: 6,

  /**
   * Максимальная длина пароля.
   */
  MAX_LENGTH: 18,

  /**
   * Массив специальных символов, которые должны встречаться в пароле.
   */
  SPECIAL_CHARS: ['!', '@', '#', '№', '$', '%', '^', '&', '?', '*', '(', ')'],
};

export default PasswordStrengthConfig;
