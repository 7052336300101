/**
 * Тип данных, передаваемых в запросе.
 */
type Type = 'form' | 'json';

/**
 * Тип ответа сервера - JSON или обычный текст.
 */
type ResponseType = 'text' | 'json';

/**
 * Представляет ошибку HTTP.
 */
export default class HttpError extends Error {
  /**
   * Тип данных, который должен был быть передан в запросе - форма или JSON.
   */
  public readonly type: Type;

  /**
   * Метод HTTP, который был использован при создании запроса к серверу.
   */
  public readonly method: string;

  /**
   * Адрес, который был использован при создании запроса к серверу.
   */
  public readonly url: string;

  /**
   * Данные, которые были использованы при создании запроса к серверу.
   */
  public readonly data: any;

  /**
   * Коллекция заголовков, которая была использована при создании запроса к
   * серверу.
   */
  public readonly headers: Record<string, string>;

  /**
   * Метод HTTP, с которым был совершен запрос, вызвавший данную ошибку.
   */
  public readonly requestMethod: string;

  /**
   * Полный URL, на который был совершен запрос.
   */
  public readonly requestUrl: string;

  /**
   * Тело запроса, вызвавшего ошибку (до преобразования в JSON).
   */
  public readonly requestBody?: string;

  /**
   * Коллекция заголовков, переданных в запросе.
   */
  public readonly requestHeaders: Record<string, string>;

  /**
   * Тип ответа сервера. Если ответ сервера удалось распознать как JSON, то
   * свойство равно `"json"`, в противном случае - `"text"`.
   */
  public readonly responseType: ResponseType;

  /**
   * Статус HTTP ответа сервера.
   */
  public readonly responseStatus: number;

  /**
   * Тело ответа сервера в том виде, в котором оно было получено.
   */
  public readonly responseBody?: string;

  /**
   * Тело ответа сервера после попытки преобразования из JSON. Если тело ответа
   * не было распознано как JSON, то значение этого свойства равно `undefined`.
   */
  public readonly responseData: any;

  /**
   * Создаёт экземпляр ошибки с указанным уникальным кодом.
   * @param type Тип данных, переданных в запросе (форма или JSON).
   * @param method Исходный метод HTTP, который был использован для создания
   * запроса.
   * @param url Исходный URL, который был использован для создания запроса.
   * @param data Данные, которые должны были быть переданы в запросе.
   * @param headers Коллекция заголовков, которая должна была быть передана
   * в запросе.
   * @param requestMethod Метод HTTP, с которым в действительности был совершен
   * запрос.
   * @param requestUrl Адрес, на который в действительности был совершен запрос.
   * @param requestBody Тело запроса в виде строки.
   * @param requestHeaders Коллекция заголовков запроса, которая была передана
   * в запрос на самом деле.
   * @param responseStatus HTTP статус ответа сервера.
   * @param responseType Тип ответа сервера (JSON или обычный текст).
   * @param responseBody Тело ответа сервера.
   * @param responseData Тело ответа сервера после попытки преобразования из
   * JSON. Если ответ не является JSON, то значение этого параметра должно быть
   * `undefined`.
   */
  public constructor(
    type: Type,
    method: string,
    url: string,
    data: any,
    headers: Record<string, string>,
    requestMethod: string,
    requestUrl: string,
    requestBody: string | undefined,
    requestHeaders: Record<string, string>,
    responseStatus: number,
    responseType: ResponseType,
    responseBody: string | undefined,
    responseData: any,
  ) {
    super(
      `[${requestMethod}] "${requestUrl}": An error ${responseStatus} occurred`,
    );
    this.name = 'HttpError';

    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    const proto = new.target.prototype;

    if (Object.setPrototypeOf != null) {
      Object.setPrototypeOf(this, proto);
    } else {
      // @ts-ignore
      // eslint-disable-next-line no-proto
      this.__proto__ = proto;
    }

    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.type = type;
    this.method = method;
    this.url = url;
    this.data = data;
    this.headers = headers;

    this.requestMethod = requestMethod;
    this.requestUrl = requestUrl;
    this.requestBody = requestBody;
    this.requestHeaders = requestHeaders;

    this.responseStatus = responseStatus;
    this.responseType = responseType;
    this.responseBody = responseBody;
    this.responseData = responseData;
  }
}
