import { ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

/**
 * Стилизация обертки табов.
 */
const MuiTabs: ThemeOptions = {
  overrides: {
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${Palette.palette?.border}`,
        minHeight: 52,
      },
    },
  },
};

export default MuiTabs;
