import { SimplePaletteColorOptions, ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

const primary = Palette.palette?.primary as SimplePaletteColorOptions;

/**
 * Стилизация элемента сортировки в таблице.
 */
const MuiTableSortLabel: ThemeOptions = {
  overrides: {
    MuiTableSortLabel: {
      active: {},

      icon: {
        fontSize: 'inherit',
        marginLeft: 2,
        marginRight: 2,
      },

      root: {
        color: primary.main,
        fontSize: '0.8125rem',
        lineHeight: 1.231,

        '&:hover': {
          color: primary.main,

          '& $icon': {
            opacity: 1,
          },
        },

        '&$active': {
          color: primary.main,

          '&$root$active $icon': {
            color: primary.main,
          },
        },
      },
    },
  },
};

export default MuiTableSortLabel;
