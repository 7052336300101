import { SimplePaletteColorOptions, ThemeOptions } from '@material-ui/core';

import Palette from './Palette';
import Shape from './Shape';

const error = Palette.palette?.error as SimplePaletteColorOptions;
const primary = Palette.palette?.primary as SimplePaletteColorOptions;

/**
 * Стилизация поля ввода FilledInput.
 */
const MuiFilledInput: ThemeOptions = {
  overrides: {
    MuiFilledInput: {
      disabled: {},

      error: {},

      input: {
        height: '1.125rem',
        padding: '19px 12px',
      },

      inputMultiline: {
        height: 'auto',
        padding: 0,
        scrollbarColor: `${primary.light} transparent`,
        scrollbarWidth: 'thin',

        '&::-webkit-scrollbar': {
          width: 4,
        },

        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: primary.light,
          borderRadius: 2,
          border: `2px solid ${primary.light}`,
        },
      },

      inputMarginDense: {
        height: '1.0625rem',
        paddingBottom: 10,
        paddingTop: 10,
      },

      marginDense: {
        fontSize: '0.875rem',
        lineHeight: 1.214,

        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },

        '& .MuiSelect-iconFilled': {
          top: 'calc(50% - 8px)',
        },
      },

      multiline: {
        '&$marginDense': {
          paddingBottom: 10,
          paddingTop: 10,
        },
      },

      root: {
        backgroundColor: Palette.palette?.action?.active,
        boxShadow: `inset 0 0 0 1px ${Palette.palette?.border}`,
        borderRadius: Shape.shape?.borderRadius,
        fontSize: '0.9375rem',
        lineHeight: 1.2,

        '&:hover': {
          backgroundColor: Palette.palette?.action?.hover,
          boxShadow: `inset 0 0 0 1px ${primary.border}`,
        },

        '&$focused': {
          backgroundColor: Palette.palette?.background?.paper,
          boxShadow: `inset 0 0 0 2px ${primary.main}`,

          '& .MuiSelect-filled': {
            backgroundColor: Palette.palette?.background?.paper,
            borderRadius: Shape.shape?.borderRadius,
            margin: 2,
          },

          '.MuiInputLabel-filled + & $input.MuiSelect-filled': {
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 46,
            paddingTop: 26,
          },

          '.MuiInputLabel-filled + & $input.MuiSelect-filled.MuiFilledInput-inputAdornedStart':
            {
              marginLeft: 0,
              paddingLeft: 0,
            },

          '& $inputMarginDense.MuiSelect-filled': {
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 38,
            paddingTop: 8,
          },

          '& $inputMarginDense.MuiSelect-filled.MuiFilledInput-inputAdornedStart':
            {
              marginLeft: 0,
              paddingLeft: 0,
            },

          '.MuiInputLabel-filled + & $inputMarginDense.MuiSelect-filled': {
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 38,
            paddingTop: 8,
          },
        },

        '&$disabled': {
          backgroundColor: Palette.palette?.action?.active,
          boxShadow: `inset 0 0 0 1px ${Palette.palette?.border}`,
          opacity: 0.5,
        },

        '&$error': {
          backgroundColor: error.lightBackground,
          boxShadow: `inset 0 0 0 2px ${error.main}`,
        },

        '.MuiInputLabel-filled + & $input:not(.MuiFilledInput-inputMultiline)':
          {
            paddingBottom: 10,
            paddingTop: 28,
          },

        '.MuiInputLabel-filled + & $inputMarginDense:not(.MuiFilledInput-inputMultiline)':
          {
            paddingBottom: 10,
            paddingTop: 10,
          },
      },

      underline: {
        '&::after': {
          display: 'none',
        },

        '&::before': {
          display: 'none',
        },
      },
    },
  },
};

export default MuiFilledInput;
