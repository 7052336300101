/**
 * Представляет компонент сложности пароля.
 */
enum PasswordStrengthRule {
  /**
   * Минимальная длина пароля (пароль должен быть не менее N символов).
   */
  HAS_MIN_LENGTH = 'has_min_length',

  /**
   * Максимальная длина пароля (пароль должен быть не более N символов).
   */
  HAS_MAX_LENGTH = 'has_max_length',

  /**
   * Содержит латиницу нижнем регистре.
   */
  HAS_LOWERCASE_LATIN_CHAR = 'has_lowercase_latin_char',

  /**
   * Содержит латиницу в верхнем регистре.
   */
  HAS_UPPERCASE_LATIN_CHAR = 'has_uppercase_latin_char',

  /**
   * Содержит как минимум одну цифру.
   */
  HAS_DIGIT = 'has_digit',

  /**
   * Содержит служебные символы ('!', '@' и тому подобное).
   */
  HAS_SPECIAL_CHAR = 'has_special_char',
}

export default PasswordStrengthRule;
