import { Link as MuiLink } from '@material-ui/core';

import React, { ComponentPropsWithoutRef, FC } from 'react';
import NextLink from 'next/link';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof MuiLink> & {
  /**
   * Адрес навигации.
   */
  href: string;
};

/**
 * Ссылка из библиотеки Material UI, подключённая к роутеру NextJS.
 */
const Link: FC<Props> = ({ href, ...props }) => (
  <NextLink href={href}>
    <MuiLink {...props} href={href} />
  </NextLink>
);

export default Link;
