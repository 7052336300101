/**
 * Конечная точка API.
 */
enum ApiEndpoint {
  /**
   * Используется для перевыпуска пары токенов.
   */
  RENEW_AUTH_TOKENS = '/auth/refresh-token',

  /**
   * Используется для отправки логина и пароля пользователя при входе в систему.
   */
  SUBMIT_LOGIN_CREDENTIALS = '/auth/signin',

  /**
   * Используется для отправки кода подтверждения входа в систему.
   */
  SUBMIT_LOGIN_CONFIRMATION_CODE = '/auth/signin',

  /**
   * Используется для повторной отправки кода подтверждения входа в систему.
   */
  RESEND_LOGIN_CONFIRMATION_CODE = '/auth/resend-factor',

  /**
   * Используется, чтобы получить список договоров
   */
  GET_CONTRACTS_COLLECTION = '/api/v1/loans',

  /**
   * Используется для получения списка сотрудников, зарегестрированных в системе.
   */
  GET_EMPLOYEES_LIST = '/api/v1/employees',

  /**
   * Используется для получения данных о договоре.
   */
  GET_LOAN_INFO = '/api/v1/loans/:id',

  /**
   * Используется для действий над списком договоров.
   */
  EDIT_LOAN_INFO = '/api/v1/loans/:id',

  /**
   * Используется для получения данных истории по договору.
   */
  GET_LOAN_HISTORY = '/api/v1/loans/:id/history-logs',

  /**
   * Используется для получения данных о платежах по договору.
   */
  GET_LOAN_PAYMENTS = '/api/v1/loans/:id/payments',

  /**
   * Используется для получения данных о заёмщике, за которым закреплён договор.
   */
  GET_LOAN_DEBTOR = '/api/v1/loans/:id/client-profile',

  /**
   * Используется для сохранения данных о заёмщике.
   */
  EDIT_LOAN_DEBTOR = '/api/v1/loans/:id/client-profile',

  /**
   * Используется для сохранения адреса заёмщика.
   */
  EDIT_LOAN_DEBTOR_ADDRESS = '/api/v1/loans/:id/client-profile/addresses/:addressId',

  /**
   * Используется для получения данных по Оплате ГП.
   */
  GET_GOSFEE = '/api/v1/legal/state-fee-payment-requests',

  /**
   * Используется для действий над договором в Оплате ГП.
   */
  CHANGE_GOSFEE_ACTION = '/api/v1/legal/state-fee-payment-requests/:id',

  /**
   * Используется для получения данных по Оплате ГП в карточке договора
   */
  GET_LOAN_GOSFEE = '/api/v1/loans/:id/state-fee-payment-requests/actual',

  /**
   * Используется для получения данных о последней проверке займа на банкротство.
   */
  GET_LOAN_BANKRUPTCY = '/api/v1/legal/bankrupt/:id',

  /**
   * Используется для действий с исключениями в проверке банкротства.
   */
  EDIT_LOAN_BANKRUPTCY_BLOCK = '/api/v1/legal/bankrupt/:id/block',

  /**
   * Используется для действий с исключениями в проверке банкротства.
   */
  EDIT_LOAN_BANKRUPTCY = '/api/v1/legal/bankrupt/:id',

  /**
   * Используется для получения статуса о блокировки на проверку.
   */
  GET_LOAN_BANKRUPTCY_BLOCK_STATUS = '/api/v1/legal/bankrupt/:id/block/status',

  /**
   * Используется для получения прикепленных к договору файлов
   */
  GET_LOAN_ATTACHMENTS = '/api/v1/loans/:id/attachments',

  /**
   * Используется для загрузки нового аттачмента
   */
  SEND_LOAN_ATTACHMENT = '/api/v1/loans/:id/attachments',

  /**
   * Удалить аттачмент с сервера
   */
  DELETE_LOAN_ATTACHMENT = '/api/v1/loans/attachments/:id',

  /**
   * Используется для получения списка проверок подсудности.
   */
  GET_LOAN_JURISDICTION_QUERIES = '/api/v1/loans/:id/jurisdiction-searches',

  /**
   * Используется для получения данных о подсудности.
   */
  GET_LOAN_JURISDICTION_DATA = '/api/v1/loans/:id/jurisdiction',

  /**
   * Используется для редактирования информации о подсудности.
   */
  EDIT_LOAN_JURISDICTION = '/api/v1/loans/:id/jurisdiction',

  /**
   * Используется для получения настроек подсудности.
   */
  GET_LOAN_JURISDICTION_SETTINGS = '/api/v1/loans/:id/jurisdiction/settings',

  /**
   * Используется для сохранения настроек подсудности.
   */
  EDIT_LOAN_JURISDICTION_SETTINGS = '/api/v1/loans/:id/jurisdiction/settings',

  /**
   * Используется для получения данных о задолженности.
   */
  GET_LOAN_DEBT = '/api/v1/loans/:id/debt-calculation',

  /**
   * Используется для получения данных о залогиненном пользователе.
   */
  GET_USER_INFO = '/api/v1/employees/me',

  /**
   * Используется для запроса на историю действий по договорам
   */
  GET_HISTORY = '/api/v1/loans/history-status',

  /**
   * Используется для получения адресов БКИ.
   */
  GET_ADDRESSES_KI = '/api/v1/loans/:id/bki-info',

  /**
   * Используется для запроса на файл для печати одного договора
   */
  GET_CONTRACT_PRINTABLE_FILE = '/api/v1/loans/:id/print-file',

  /**
   * Используется для получения фоновых задач по сотруднику.
   */
  GET_EMPLOYEE_BACKGROUND_TASKS = '/api/v1/background-tasks/employee/:id',

  /**
   * Используется для создания фоновой задачи.
   */
  START_BACKGROUND_TASK = '/api/v1/background-tasks/:type',

  /**
   * Используется для получения информации по фоновой задаче.
   */
  GET_BACKGROUND_TASK = '/api/v1/background-tasks/:id',

  /**
   * Используется для получения списка комментариев СУ.
   */
  GET_JUDICIAL_SECTOR_COMMENTS = '/api/v1/courts/comments',

  /**
   * Список юридических лиц.
   */
  GET_LEGAL_ENTITY_LIST = '/api/v1/companies',

  /**
   * Список СУ.
   */
  GET_COURTS_LIST = '/api/v1/courts',

  /**
   * Используется для прикрепления комментария СУ.
   */
  ATTACH_COURT_COMMENT = '/api/v1/courts/:courtId/comments/:commentId',

  /**
   * Используется для открепления комментария СУ.
   */
  DETACH_COURT_COMMENT = '/api/v1/courts/:courtId/comments/:commentId',

  /**
   * Список регионов СУ.
   */
  GET_COURTS_REGIONS = '/api/v1/courts/regions',
}

export default ApiEndpoint;
