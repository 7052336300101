import * as Yup from 'yup';

import FieldErrorText from 'constants/FieldErrorText';
import CodeHelper from 'helpers/CodeHelper';
import DateHelper from 'helpers/DateHelper';
import PasswordHelper from 'helpers/PasswordHelper';
import PhoneHelper from 'helpers/PhoneHelper';

/**
 * Здесь происходит расширение валидатора Yup. При добавлении новых валидаторов
 * ОБЯЗАТЕЛЬНО следует добавлять их типы в `@types/yup.d.ts`, иначе Typescript
 * их просто не увидит.
 */

/**
 * Добавляем схему валидации телефонного номера.
 */
Yup.addMethod(
  Yup.string,
  'phone',
  function validatePhone(message: string = FieldErrorText.BAD_PHONE_FORMAT) {
    return this.test({
      message,
      test(value = '') {
        return !value || PhoneHelper.validatePrettyFormat(value);
      },
    });
  },
);

/**
 * Добавляем схему валидации мобильного номера телефона.
 */
Yup.addMethod(
  Yup.string,
  'mobilePhone',
  function validatePhone(message: string = FieldErrorText.NOT_MOBILE_PHONE) {
    return this.test({
      message,
      test(value = '') {
        return (
          !value ||
          PhoneHelper.isMobile(PhoneHelper.convertPrettyToSystem(value))
        );
      },
    });
  },
);

/**
 * Добавляем схему валидации кода подтверждения.
 */
Yup.addMethod(
  Yup.string,
  'code',
  function validateCode(message: string = FieldErrorText.BAD_CODE_FORMAT) {
    return this.test({
      message,
      test(value = '') {
        return !value || CodeHelper.validatePrettyFormat(value);
      },
    });
  },
);

/**
 * Добавляем схему валидации сложности пароля.
 */
Yup.addMethod(
  Yup.string,
  'passwordStrength',
  function validatePasswordStrength(message?: string) {
    return this.test({
      test(value = '') {
        if (!value) {
          return true;
        }

        const strength = PasswordHelper.getStrength(value);

        return PasswordHelper.validateStrength(strength)
          ? true
          : this.createError({
              message:
                message == null
                  ? PasswordHelper.convertStrengthToYupError(strength)
                  : message,
            });
      },
    });
  },
);

/**
 * Коллекция сообщений об ошибках валидации даты.
 */
type DateMessages = {
  /**
   * Возвращается, если дата имеет неверный формат.
   */
  format: string;

  /**
   * Возвращается, если дата имеет верный формат, но не существует (к примеру,
   * '31.02.1991').
   */
  params: string;
};

/**
 * Добавляем схему валидации даты.
 */
Yup.addMethod(
  Yup.string,
  'date',
  function validateDate(message?: DateMessages | string) {
    return this.test({
      message,
      test(value = '') {
        if (!value) {
          return true;
        }

        const formatMessage =
          typeof message === 'string'
            ? message
            : message?.format ?? FieldErrorText.BAD_DATE_FORMAT;

        const paramsMessage =
          typeof message === 'string'
            ? message
            : message?.params ?? FieldErrorText.BAD_DATE_PARAMS;

        if (!DateHelper.validatePrettyFormat(value)) {
          return this.createError({ message: formatMessage });
        }

        const date = DateHelper.convertPrettyToSystem(value);

        if (!DateHelper.isExists(date)) {
          return this.createError({ message: paramsMessage });
        }

        return true;
      },
    });
  },
);

/**
 * Добавляем схему валидации даты в будущем.
 */
Yup.addMethod(
  Yup.string,
  'notFuture',
  function validateNotFuture(message: string = FieldErrorText.FUTURE_DATE) {
    return this.test({
      message,
      test(value = '') {
        if (!value) {
          return true;
        }

        const currentDate = DateHelper.convertNativeToSystem(new Date());
        const date = DateHelper.convertPrettyToSystem(value);

        return DateHelper.compare(date, currentDate) <= 0;
      },
    });
  },
);

Yup.addMethod(
  Yup.string,
  'dateOrder',
  function validateDateOrder(
    message: string = FieldErrorText.BAD_DATE_ORDER,
    fromField: string = 'dateFrom',
    tillField: string = 'dateTill',
  ) {
    return this.test({
      message,
      exclusive: true,
      name: 'dateOrder',
      test(_, context) {
        const { [fromField]: dateFrom, [tillField]: dateTill } = context.parent;

        if (!dateFrom || !dateTill) {
          return true;
        }

        const minDate = DateHelper.validatePrettyFormat(dateFrom)
          ? DateHelper.convertPrettyToSystem(dateFrom)
          : '';

        const maxDate = DateHelper.validatePrettyFormat(dateTill)
          ? DateHelper.convertPrettyToSystem(dateTill)
          : '';

        return (
          !minDate || !maxDate || DateHelper.compare(minDate, maxDate) <= 0
        );
      },
    });
  },
);
