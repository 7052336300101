import { addDays, format } from 'date-fns';

import FormatRegexp from 'constants/FormatRegexp';

/**
 * Содержит низкоуровневые методы для работы с датами.
 */
export default class DateHelper {
  /**
   * Преобразует объект даты в строку
   * в формате {@link FormatRegexp.PRETTY_DATE}
   * @param value
   * @returns
   */
  public static convertNativeToPretty(value?: Date | number) {
    if (!value) {
      return '—';
    }

    return format(value, 'dd.MM.yyyy');
  }

  /**
   * Преобразует объект даты в строку.
   * @param value
   */
  public static convertNativeToPrettyWithTime(value?: Date | number) {
    if (!value) {
      return '—';
    }

    return format(value, 'HH:mm, dd.MM.yyyy');
  }

  /**
   * Преобразует данный объект даты в строку в формате
   * {@link FormatRegexp.SYSTEM_DATE}.
   * @param value Объект даты.
   */
  public static convertNativeToSystem(value?: Date) {
    if (!value) {
      return '—';
    }

    return format(value, 'yyyy-MM-dd');
  }

  /**
   * Преобразует данный объект даты в строку в формате
   * {@link FormatRegexp.SYSTEM_DATE_WITH_TIME}.
   * @param value Объект даты.
   */
  public static convertNativeToSystemWithTime(value: Date | number) {
    return format(value, 'yyyy-MM-dd HH:mm');
  }

  /**
   * Возвращает `true`, если переданная строка является датой в формате
   * формате {@link FormatRegexp.PRETTY_DATE}.
   * @param value Строка.
   */
  public static validatePrettyFormat(value: string) {
    return FormatRegexp.PRETTY_DATE.test(value);
  }

  /**
   * Возвращает `true`, если переданная строка является датой в формате
   * формате {@link FormatRegexp.SYSTEM_DATE}.
   * @param value Строка.
   */
  public static validateSystemFormat(value: string) {
    return FormatRegexp.SYSTEM_DATE.test(value);
  }

  /**
   * Возвращает `true`, если переданная строка является датой в формате
   * формате {@link FormatRegexp.SYSTEM_DATE_WITH_TIME}.
   * @param value Строка.
   */
  public static validateSystemFormatWithTime(value: string) {
    return FormatRegexp.SYSTEM_DATE_WITH_TIME.test(value);
  }

  /**
   * Преобразует дату в формате {@link FormatRegexp.SYSTEM_DATE} в
   * {@link FormatRegexp.PRETTY_DATE}.
   * @param systemDate Дата в формате {@link FormatRegexp.SYSTEM_DATE}.
   */
  public static convertSystemToPretty(systemDate: string) {
    if (this.validateSystemFormat(systemDate)) {
      return systemDate.replace(FormatRegexp.SYSTEM_DATE, '$3.$2.$1');
    }

    if (this.validateSystemFormatWithTime(systemDate)) {
      return systemDate.replace(FormatRegexp.SYSTEM_DATE_WITH_TIME, '$3.$2.$1');
    }

    throw new Error(`Expect 'systemDate' to be a date; got ${systemDate}`);
  }

  /**
   * Преобразует дату в формате {@link FormatRegexp.PRETTY_DATE} в
   * {@link FormatRegexp.SYSTEM_DATE}.
   * @param prettyDate Дата в формате {@link FormatRegexp.PRETTY_DATE}.
   */
  public static convertPrettyToSystem(prettyDate: string) {
    if (this.validatePrettyFormat(prettyDate)) {
      return prettyDate.replace(FormatRegexp.PRETTY_DATE, '$3-$2-$1');
    }

    throw new Error(`Expect 'prettyDate' to be a date; got ${prettyDate}`);
  }

  /**
   * Проверяет, существует ли указанная дата, и возвращает `true`, если да.
   * К примеру, дата '2020-31-64' не существует, хотя и записана в верном
   * формате.
   * @param systemDate Дата в формате {@link FormatRegexp.SYSTEM_DATE}.
   */
  public static isExists(systemDate: string) {
    const match = systemDate.match(FormatRegexp.SYSTEM_DATE);

    if (!match) {
      throw new Error(`Expect 'systemDate' to be a date; got ${systemDate}`);
    }

    const parsedMonth = Number(match[2]) - 1;
    const parsedYear = Number(match[1]);
    const parsedDay = Number(match[3]);

    const date = new Date(systemDate);
    const computedMonth = date.getMonth();
    const computedYear = date.getFullYear();
    const computedDay = date.getDate();

    return (
      parsedMonth === computedMonth &&
      parsedYear === computedYear &&
      parsedDay === computedDay
    );
  }

  /**
   * Сравнивает две указанные даты и возвращает число меньше нуля, если первая
   * меньше второй; ноль - если они равны, и число больше нуля - если первая
   * дата больше второй.
   * @param dateA Первая дата
   * @param dateB Вторая дата.
   */
  public static compare(dateA: string, dateB: string) {
    const valueA = new Date(dateA);
    const valueB = new Date(dateB);
    return valueA.getTime() - valueB.getTime();
  }

  /**
   * Прибавляет к дате в системном формате указанное количество
   * дней и возвращает дату в будущем.
   * @param systemDate Дата в формате {@link FormatRegexp.SYSTEM_DATE_WITH_TIME}.
   * @param days Количество дней.
   */
  public static getDateInFuture(systemDate: string, days: number) {
    const date = new Date(systemDate);
    const dateInFuture = addDays(date, days);
    return format(dateInFuture, 'dd.MM.yyyy');
  }
}
