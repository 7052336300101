import { ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

/**
 * Стилизация таба.
 */
const MuiTab: ThemeOptions = {
  overrides: {
    MuiTab: {
      disabled: {},

      root: {
        lineHeight: 1.214,
        minHeight: 64,
        minWidth: 140,
        textTransform: 'none',

        '@media (min-width: 0)': {
          minWidth: 140,
        },
      },

      selected: {},

      textColorPrimary: {
        '&$disabled': {
          fontWeight: 400,
        },

        '&$selected': {
          color: Palette.palette?.text?.primary,
        },
      },
    },
  },
};

export default MuiTab;
