import { useContext } from 'react';

import SessionContext from 'components/contexts/SessionContext';

/**
 * Возвращает значение контекста сессии.
 */
export default function useSession() {
  return useContext(SessionContext);
}
