import React, { FC } from 'react';

import { Typography } from '@material-ui/core';

import Button from '../ui/Button';
import Dialog from '../ui/Dialog';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Определяет активно ли диалоговое окно.
   */
  active: boolean;

  /**
   * Обработчик события закрытия диалогового окна.
   */
  onClose?: () => void;
};

/**
 * Отображает диалог, сообщающий об истечении сессии.
 */
const SessionExpiredDialog: FC<Props> = ({ active, onClose }) => (
  <Dialog
    open={active}
    disableEscapeKeyDown
    title="Сообщение"
    maxWidth="xs"
    fullWidth
    actions={
      <>
        <Button variant="text" color="primary" onClick={onClose}>
          Закрыть
        </Button>
      </>
    }
  >
    <Typography variant="subtitle2">Сессия истекла</Typography>
    <Typography variant="body2">Пожалуйста, повторите вход</Typography>
  </Dialog>
);

export default SessionExpiredDialog;
