import { SimplePaletteColorOptions, ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

const error = Palette.palette?.error as SimplePaletteColorOptions;

/**
 * Стилизация поля ввода TextField.
 */
const MuiFormHelperText: ThemeOptions = {
  overrides: {
    MuiFormHelperText: {
      contained: {
        marginLeft: 16,
        marginRight: 12,
      },

      error: {},

      root: {
        color: Palette.palette?.text?.primary,
        letterSpacing: '0.4px',
        lineHeight: 1.333,
        marginTop: 8,

        '&$error': {
          color: error.dark,
        },
      },
    },
  },
};

export default MuiFormHelperText;
