import { ThemeOptions } from '@material-ui/core';
import { Palette as PaletteType } from '@material-ui/core/styles/createPalette';

import Palette from './Palette';

const palette = Palette.palette as PaletteType;

/**
 * Стилизация тултипа
 */

const MuiTooltip: ThemeOptions = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        paddingTop: 8,
        paddingBottom: 8,

        fontSize: 12,

        backgroundColor: palette.info.darkText,
        borderRadius: 4,
      },

      arrow: {
        color: palette.info.darkText,
      },
    },
  },
};

export default MuiTooltip;
