import PasswordStrengthConfig from 'constants/PasswordStrengthConfig';
import PasswordStrengthRule from 'constants/PasswordStrengthRule';
import PasswordStrength from 'types/PasswordStrength';

/**
 * Содержит методы для работы с паролями.
 */
export default class PasswordHelper {
  /**
   * Префикс в служебном сообщении об ошибке валидации сложности пароля.
   */
  private static ERROR_PREFIX = 'password_strength:';

  /**
   * Возвращает `true`, если длина пароля более или равна минимальному значению.
   * @param password Пароль.
   */
  private static hasMinLength(password: string) {
    return password.length >= PasswordStrengthConfig.MIN_LENGTH;
  }

  /**
   * Возвращает `true`, если длина пароля менее или равна минимальному значению.
   * @param password Пароль.
   */
  private static hasMaxLength(password: string) {
    return password.length <= PasswordStrengthConfig.MAX_LENGTH;
  }

  /**
   * Возвращает `true`, если пароль содержит как минимум один латинский символ
   * в верхнем регистре.
   * @param password Пароль.
   */
  private static hasUppercaseLatinChar(password: string) {
    return /[A-Z]/.test(password);
  }

  /**
   * Возвращает `true`, если пароль содержит как минимум один латинский символ
   * в нижнем регистре.
   * @param password Пароль.
   */
  private static hasLowercaseLatinChar(password: string) {
    return /[a-z]/.test(password);
  }

  /**
   * Возвращает `true`, если пароль содержит как минимум одну цифру.
   * @param password Пароль.
   */
  private static hasDigit(password: string) {
    return /\d/.test(password);
  }

  /**
   * Возвращает `true`, если пароль содержит как минимум один специальный
   * символ.
   * @param password Пароль.
   */
  private static hasSpecialChar(password: string) {
    return PasswordStrengthConfig.SPECIAL_CHARS.some((char) =>
      password.includes(char),
    );
  }

  /**
   * Определяет сложность указанного пароля.
   * @param password Пароль.
   */
  public static getStrength(password: string): PasswordStrength {
    return {
      [PasswordStrengthRule.HAS_SPECIAL_CHAR]: this.hasSpecialChar(password),
      [PasswordStrengthRule.HAS_MIN_LENGTH]: this.hasMinLength(password),
      [PasswordStrengthRule.HAS_MAX_LENGTH]: this.hasMaxLength(password),
      [PasswordStrengthRule.HAS_DIGIT]: this.hasDigit(password),
      [PasswordStrengthRule.HAS_UPPERCASE_LATIN_CHAR]:
        this.hasUppercaseLatinChar(password),
      [PasswordStrengthRule.HAS_LOWERCASE_LATIN_CHAR]:
        this.hasLowercaseLatinChar(password),
    };
  }

  /**
   * Возвращает объект сложности пароля, в котором все критерии соблюдены.
   */
  public static getValidStrength() {
    return Object.values(PasswordStrengthRule).reduce(
      (strength, rule) => ({
        ...strength,
        [rule]: true,
      }),
      {} as PasswordStrength,
    );
  }

  /**
   * Преобразует сложность пароля в сообщение об ошибке валидатора Yup.
   * @param strength Сложность пароля.
   */
  public static convertStrengthToYupError(strength: PasswordStrength) {
    return `${this.ERROR_PREFIX}${JSON.stringify(strength)}`;
  }

  /**
   * Преобразуте сообщение об ошибке валидатора Yup в сложность пароля. Если
   * переданный текст не является служебным сообщением, полученным с помощью
   * метода `self.convertStrengthToYupError()`, возвращает `undefined`.
   * @param error Сообщение об ошибке.
   */
  public static convertYupErrorToStrength(error: string) {
    if (!error.startsWith(this.ERROR_PREFIX)) {
      return undefined;
    }

    const json = error.substr(this.ERROR_PREFIX.length);
    return JSON.parse(json) as PasswordStrength;
  }

  /**
   * Возвращает `true`, если в указанном результате проверки все компоненты
   * сложности соблюдены.
   * @param strength Сложность пароля.
   */
  public static validateStrength(strength: PasswordStrength) {
    return Object.values(strength).every(Boolean);
  }
}
