import React, { FC } from 'react';

import {
  Dialog as MuiDialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

/**
 * Возвращает классы элементов.
 */
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    lineHeight: 1.6,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
  },

  actions: {
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },

  content: {
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    borderBottom: 'none',
  },

  subtitle: {
    marginTop: theme.spacing(2),
  },

  formField: {
    marginTop: theme.spacing(1),
  },
}));

type Props = DialogProps & {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  classes?: {
    actions?: string;
  };
};

/**
 * Отображает всплывающее окно
 */
const Dialog: FC<Props> = ({
  title,
  actions,
  children,
  classes = {},
  ...props
}: Props) => {
  const styles = useStyles();

  return (
    <MuiDialog {...props}>
      {title && <DialogTitle className={styles.title}>{title}</DialogTitle>}
      <DialogContent dividers className={styles.content}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions className={classnames(styles.actions, classes.actions)}>
          {actions}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
