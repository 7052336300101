import { ThemeOptions } from '@material-ui/core';

/**
 * Стилизация элемента меню.
 */
const MuiMenuItem: ThemeOptions = {
  overrides: {
    MuiMenuItem: {
      root: {
        fontSize: '0.875rem',
        lineHeight: 1.214,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
      },
    },
  },
};

export default MuiMenuItem;
