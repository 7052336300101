import { useContext } from 'react';

import ToastsContext from 'components/contexts/ToastsContext';

/**
 * Возвращает значение контекста всплывающих сообщений.
 */
export default function useToasts() {
  return useContext(ToastsContext);
}
