/* eslint-disable camelcase */

/**
 * Ключ, по которому в Local Storage хранятся значения.
 */
enum StorageKey {
  /**
   * Страница, на которую хотел попасть пользователь, но не был при этом
   * авторизован. После входа в систему его отправляет на эту страницу.
   */
  AUTH_TARGET_HREF = 'auth_target_href',

  /**
   * Токен авторизованных запросов.
   */
  ACCESS_TOKEN = 'access_token',

  /**
   * Токен для возобновления истёкшей сессии.
   */
  REFRESH_TOKEN = 'refresh_token',

  /**
   * Состояние сессии двухфакторного входа в систему.
   */
  LOGIN_SESSION = 'login_session',

  /**
   * Временная метка обратного отсчёта до момента, когда пользователю
   * станет доступна повторная отправка кода подтверждения при входе в
   * систему.
   */
  LOGIN_COUNTDOWN = 'login_countdown',

  /**
   * Состояние сессии восстановления пароля.
   */
  RECOVERY_SESSION = 'recovery_session',

  /**
   * Токен, который используется для сессии восстановления пароля пользователя.
   */
  RECOVERY_SESSION_TOKEN = 'recovery_session_token',

  /**
   * Телефон, на который должно прийти СМС при восстановлении пароля
   * пользователя.
   */
  RECOVERY_PHONE = 'recovery_phone',

  /**
   * Код подтверждения, который ввёл пользователь для восстановления пароля.
   */
  RECOVERY_CODE = 'recovery_code',

  /**
   * Временная метка обратного отсчёта до момента, когда пользователю
   * станет доступна повторная отправка кода подтверждения при восстановлении
   * пароля.
   */
  RECOVERY_COUNTDOWN = 'recovery_countdown',

  /**
   * Хранение кол-ва отображаемых договоров, указанных пользователем.
   */
  PAGING_SIZE = 'paging_size',

  /**
   * Хранение массива "прочитанных" оповещений.
   */
  VIEWED_TASKS = 'viewed_tasks',
}

export default StorageKey;
