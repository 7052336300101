import { SimplePaletteColorOptions, ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

const primary = Palette.palette?.primary as SimplePaletteColorOptions;
const secondary = Palette.palette?.secondary as SimplePaletteColorOptions;

/**
 * Конфигурация кнопок IconButton.
 */
const MuiIconButton: ThemeOptions = {
  overrides: {
    MuiIconButton: {
      colorPrimary: {
        color: primary.light,

        '&:hover': { backgroundColor: primary.background },
      },

      colorSecondary: {
        color: secondary.light,

        '&:hover': { backgroundColor: secondary.background },
      },

      sizeSmall: {
        padding: 4,

        '&$edgeEnd': {
          marginRight: -4,
        },

        '&$edgeStart': {
          marginLeft: -4,
        },
      },
    },
  },
};

export default MuiIconButton;
