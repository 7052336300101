import { compile } from 'path-to-regexp';
import Url from 'url-parse';

/**
 * Параметры запроса.
 */
type Query = Record<string, string>;

/**
 * Функция компиляции адреса.
 */
type Compiler = ReturnType<typeof compile>;

/**
 * Содержит методы для работы с маршрутами приложения.
 */
export default class RouteHelper {
  /**
   * Кэш функций компиляции путей из маршрутов.
   */
  private static compilers = new Map<string, Compiler>();

  /**
   * Подставляет в маршрут указанную коллекцию значений и возвращает
   * получившийся путь.
   * @param route Маршрут.
   * @param params Параметры машрута.
   */
  public static compile(
    route: string,
    params?: Record<string, string | number>,
  ) {
    let compiler = this.compilers.get(route);

    if (compiler == null) {
      compiler = compile(route);
      this.compilers.set(route, compiler);
    }

    return compiler(params);
  }

  /**
   * Преобразует указанный URL в строку.
   * @param url URL.
   */
  private static stringifyUrl(url: Url) {
    return url.toString().replace(/^.+:\/\/[^/?]+/, '');
  }

  /**
   * Возвращает объект, представляющий указанный адрес страницы.
   * @param url Адрес страницы.
   */
  private static parseUrl(url: string) {
    return new Url(url, true);
  }

  /**
   * Возвращает коллекцию параметров из указанного адреса страницы.
   * @param href Адрес страницы.
   */
  public static getHrefQuery(href: string) {
    const { query } = this.parseUrl(href);
    return query;
  }

  /**
   * Добавляет в указанную ссылку дополнительные параметры запроса и возвращает
   * новую ссылку.
   * @param href Исходная ссылка.
   * @param query Параметры, которые следует в неё записать.
   */
  public static addQueryToHref(href: string, query: Query) {
    const url = this.parseUrl(href);
    const { query: previousQuery } = url;

    const nextQuery = {
      ...previousQuery,
      ...query,
    };

    url.set('query', nextQuery);
    return this.stringifyUrl(url);
  }
}
