import FormatRegexp from 'constants/FormatRegexp';

/**
 * Предоставляет методы для работы с номерами телефонов.
 */
export default class PhoneHelper {
  /**
   * Возвращает `true`, если переданная строка является номером телефона в
   * формате {@link FormatRegexp.PRETTY_PHONE}.
   * @param value Строка.
   */
  public static validatePrettyFormat(value: string) {
    return FormatRegexp.PRETTY_PHONE.test(value);
  }

  /**
   * Возвращает `true`, если переданная строка является номером телефона в
   * формате {@link FormatRegexp.SYSTEM_PHONE}.
   * @param value Строка.
   */
  public static validateSystemFormat(value: string) {
    return FormatRegexp.SYSTEM_PHONE.test(value);
  }

  /**
   * Преобразует номер телефона в формате {@link FormatRegexp.PRETTY_PHONE} в
   * {@link FormatRegexp.SYSTEM_PHONE}.
   * @param prettyPhone Номер телефона в формате
   * {@link FormatRegexp.PRETTY_PHONE}.
   */
  public static convertPrettyToSystem(prettyPhone: string) {
    if (this.validatePrettyFormat(prettyPhone)) {
      return prettyPhone.replace(FormatRegexp.PRETTY_PHONE, '$1$2$3$4');
    }

    throw new Error(`Expect 'prettyPhone' to be a phone; got ${prettyPhone}`);
  }

  /**
   * Преобразует номер телефона в формате {@link FormatRegexp.SYSTEM_PHONE} в
   * {@link FormatRegexp.PRETTY_PHONE}.
   * @param prettyPhone Номер телефона в формате
   * {@link FormatRegexp.SYSTEM_PHONE}.
   */
  public static convertSystemToPretty(systemPhone: string) {
    if (this.validateSystemFormat(systemPhone)) {
      return systemPhone.replace(FormatRegexp.SYSTEM_PHONE, '+$1 ($2) $3 $4');
    }

    throw new Error(`Expect 'systemPhone' to be a phone; got ${systemPhone}`);
  }

  /**
   * Определяет, является ли указанный телефон мобильным.
   * @param systemPhone Номер телефона в формате
   * {@link FormatRegexp.SYSTEM_PHONE}.
   */
  public static isMobile(systemPhone: string) {
    if (this.validateSystemFormat(systemPhone)) {
      return systemPhone.startsWith('79');
    }

    throw new Error(`Expect 'systemPhone' to be a phone; got ${systemPhone}`);
  }
}
