import { ThemeOptions } from '@material-ui/core';

/**
 * Стилизация меню.
 */
const MuiMenu: ThemeOptions = {
  overrides: {
    MuiMenu: {
      list: {
        paddingBottom: 0,
        paddingTop: 0,
      },

      paper: {
        maxHeight: 300,
      },
    },
  },
};

export default MuiMenu;
