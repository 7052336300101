import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { ErrorBoundary } from '@sentry/nextjs';
import { withProfiler } from '@sentry/react';
import NextApp, { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactQueryDevtools } from 'react-query/devtools';

import NextPage from 'types/NextPage';
import createTheme from 'theme/createTheme';
import { SessionContextProvider } from 'components/contexts/SessionContext';
import { ToastsContextProvider } from 'components/contexts/ToastsContext';
import { NotificationsContextProvider } from 'components/contexts/NotificationsContext';
import Toasts from 'components/toasts/Toasts';
import Error from 'components/ui/Error';

import '@fontsource/roboto';
import '@fontsource/material-icons';
import 'yupExtensions';

/**
 * Свойства компонента.
 */
type Props = AppProps & {
  /**
   * @inheritdoc
   */
  Component: NextPage;
};

/**
 * Отображает корневое представление приложения.
 */
class App extends NextApp<Props> {
  /**
   * Обёртка страницы по умолчанию.
   * @param page Страница.
   */
  private static defaultLayout: FC = ({ children }) => <>{children}</>;

  /**
   * Экземпляр клиента react-query.
   */
  readonly #queryClient = new QueryClient();

  /**
   * Экземпляр темы приложения.
   */
  readonly #theme = createTheme();

  /**
   * @inheritdoc
   */
  componentDidMount() {
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    const { Component, pageProps } = this.props;
    const Layout = Component.Layout ?? App.defaultLayout;

    return (
      <QueryClientProvider client={this.#queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ErrorBoundary
          fallback={(props) => (
            <Error traceID={props.eventId ?? undefined} value={props.error} />
          )}
        >
          <Head>
            <link rel="icon" href="/favicon.ico" />
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width"
            />
          </Head>

          <ThemeProvider theme={this.#theme}>
            <CssBaseline />
            <ToastsContextProvider>
              <NotificationsContextProvider>
                <SessionContextProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                  <Toasts />
                </SessionContextProvider>
              </NotificationsContextProvider>
            </ToastsContextProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </QueryClientProvider>
    );
  }
}

export default withProfiler(App);
