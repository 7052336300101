import { ComponentPropsWithoutRef, FC, MouseEvent } from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { useRouter } from 'next/router';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof MuiButton> & {
  /**
   * Переопределение компонента.
   */
  component?: any;

  /**
   * Адрес навигации.
   */
  href?: string;
};

/**
 * Кнопка Material UI, подключённая к роутеру NextJS.
 */
const Button: FC<Props> = ({ href, onClick, ...props }) => {
  const router = useRouter();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    if (href) {
      router.push(href);
    }

    onClick?.(e);
  }

  return <MuiButton {...props} onClick={handleClick} />;
};

export default Button;
