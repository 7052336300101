import { ThemeOptions } from '@material-ui/core';

/**
 * Конфигурация вспомогательной области контента.
 */
const AsideContent: ThemeOptions = {
  custom: {
    asideContent: {
      width: 258,
    },
  },
};

export default AsideContent;
