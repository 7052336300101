import { FC, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Box, Divider, Typography } from '@material-ui/core';

import useSession from 'hooks/useSession';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Данные об ошибке.
   */
  value: any;

  /**
   * Идентификатор проблемы. Если идентификатор отсутствует, ошибка
   * отправляется вручную и генерируется новый идентификатор.
   */
  traceID?: string;
};

/**
 * Отображает ошибку получения данных.
 */
const Error: FC<Props> = ({ value, traceID: outerTraceID }) => {
  const session = useSession();
  const [traceID, setTraceID] = useState('');

  useEffect(() => {
    if (outerTraceID) {
      setTraceID(outerTraceID);
      return;
    }

    const innerTraceID = Sentry.captureException(value, {
      user: {
        id: session.user?.id,
      },
    });
    setTraceID(innerTraceID);
  }, [value, outerTraceID, session.user?.id]);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h4">Ошибка получения данных</Typography>
      </Box>

      <Box mb={2}>
        <Typography variant="body1">
          В процессе получения данных произошла ошибка. Для скорейшего
          устранения проблемы, пожалуйста, обратитесь в поддержку, указав
          идентификатор проблемы
        </Typography>
      </Box>

      <Box my={2}>
        <Divider />
      </Box>

      <Box my={2}>
        <Box>
          <Typography variant="body2">
            <b>Идентификатор проблемы</b>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">{traceID}</Typography>
        </Box>
      </Box>

      <Box my={2}>
        <Divider />
      </Box>

      <Box my={2}>
        <Box>
          <Typography variant="body2">
            <b>Техническая информация</b>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">{String(value)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Error;
