import FormatRegexp from 'constants/FormatRegexp';

/**
 * Предоставляет методы для работы с кодами подтверждения.
 */
export default class CodeHelper {
  /**
   * Возвращает `true`, если переданная строка является кодом подтверждения
   * в формате {@link FormatRegexp.PRETTY_CODE}.
   * @param value Строка.
   */
  public static validatePrettyFormat(value: string) {
    return FormatRegexp.PRETTY_CODE.test(value);
  }

  /**
   * Возвращает `true`, если переданная строка является кодом подтверждения
   * в формате {@link FormatRegexp.SYSTEM_CODE}.
   * @param value Строка.
   */
  public static validateSystemFormat(value: string) {
    return FormatRegexp.SYSTEM_CODE.test(value);
  }

  /**
   * Преобразует код подтверждения в формате {@link FormatRegexp.PRETTY_CODE} в
   * {@link FormatRegexp.SYSTEM_CODE}.
   * @param prettyPhone Код подтверждения в формате
   * {@link FormatRegexp.PRETTY_CODE}.
   */
  public static convertPrettyToSystem(prettyCode: string) {
    if (this.validatePrettyFormat(prettyCode)) {
      return prettyCode.replace(FormatRegexp.PRETTY_CODE, '$1$2');
    }

    throw new Error(`Expect 'prettyCode' to be a code; got ${prettyCode}`);
  }

  /**
   * Преобразует код подтверждения в формате {@link FormatRegexp.SYSTEM_CODE} в
   * {@link FormatRegexp.PRETTY_CODE}.
   * @param prettyPhone код подтверждения в формате
   * {@link FormatRegexp.SYSTEM_CODE}.
   */
  public static convertSystemToPretty(systemCode: string) {
    if (this.validatePrettyFormat(systemCode)) {
      return systemCode.replace(FormatRegexp.SYSTEM_CODE, '$1-$2');
    }

    throw new Error(`Expect 'systemCode' to be a code; got ${systemCode}`);
  }
}
