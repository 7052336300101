import Url from 'url-parse';

import ApiMethod from 'constants/ApiMethod';
import Tokens from 'types/Tokens';

import HttpError from './HttpError';

/**
 * Тип данных, передаваемых в запросе: форма или JSON.
 */
type Type = 'json' | 'form';

/**
 * Данные, передаваемые в запросе.
 */
type Data = Record<string, any> | FormData;

/**
 * Коллекция заголовков запроса.
 */
type Headers = Record<string, string>;

/**
 * Тип ответа сервера - JSON или обычный текст.
 */
type ResponseType = 'text' | 'json';

/**
 * Функция, которая используется для отправки запроса к серверу.
 * @param method Метод HTTP, который будет использовать запрос.
 * @param endpoint Конечная точка API, к которой производится запрос.
 * @param body Тело запроса.
 * @param headers Коллекция заголовков запроса.
 */
type Fetch = (
  method: ApiMethod,
  endpoint: string,
  body?: Data,
  headers?: Headers,
) => Promise<any>;

/**
 * Конфигурация клиента.
 */
type Config = {
  /**
   * Базовый URL, по которому расположено API.
   */
  baseUrl: string;

  /**
   * Возвращает пару токенов авторизации в API.
   */
  getTokens: () => Tokens | undefined;

  /**
   * Перевыпускает пару токенов авторизации или выбрасывает исключение
   * {@link NotAuthorizedError}, если токен `refresh` истёк или невалиден.
   * @param fetch Функция, которая используется для отправки запроса с API.
   * @param refresh Токен, используемый для перевыпуска пары токенов.
   * @throws NotAuthorizedError Должно возникать, когда токен `refresh` истёк
   * или невалиден.
   */
  renewTokens: (fetch: Fetch, refresh: string) => Promise<Tokens>;

  /**
   * Возвращает `true`, если переданная ошибка сообщает о том, что Refresh Token
   * API не валиден или устарел. Данная проверка проводится для ошибок при
   * перевыпуске токенов авторизации.
   * @param error Ошибка.
   */
  isBadRefreshToken: (error: HttpError) => boolean;

  /**
   * Возвращает `true`, если переданная ошибка сообщает о том, что Access Token
   * API не валиден или устарел. Данная проверка производится для ошибок при
   * любом запросе к API, кроме запроса на перевыпуск пары токенов авторизации.
   * @param error Ошибка.
   */
  isBadAccessToken: (error: HttpError) => boolean;

  /**
   * Обрабатывает перевыпуск пары токенов авторизации.
   * @param tokens Новая пара токенов авторизации.
   */
  onTokensChange: (tokens: Tokens) => void;

  /**
   * Обрабатывает событие, когда API помечает текущую пару токенов авторизации
   * как просроченную.
   */
  onTokensExpire: () => void;
};

/**
 * Предоставляет возможность обмена данными c API.
 */
export default class HttpClient {
  /**
   * Настройки клиента.
   */
  private readonly config: Config;

  /**
   * Создаёт экземпляр клиента с указанными настройками.
   * @param config Настройки клиента.
   */
  public constructor(config: Config) {
    this.config = config;
  }

  /**
   * Возвращает полный URL запроса к указанной конечной точке API.
   * @param endpoint Конечная точка.
   */
  private getUrl(endpoint: string) {
    const head = this.config.baseUrl.replace(/\/+$/g, '');
    const tail = String(endpoint).replace(/^\/+|\/+$/g, '');
    const url = [head, tail].filter(Boolean).join('/') || '/';

    return url;
  }

  /**
   * Добавляет в указанную коллекцию заголовков те, которые устанавливают
   * JSON-тип содержимого запроса.
   * @param headers Исходная коллекция заголовков.
   */
  private setJsonHeaders(headers: Headers) {
    const nextHeaders: Headers = {
      ...headers,
      [`Content-Type`]: 'application/json',
      [`Accept`]: 'application/json',
    };

    return nextHeaders;
  }

  /**
   * Преобразует указанные данные в JSON-строку.
   * @param data Данные.
   */
  private stringifyJson(data: any) {
    return data == null ? undefined : JSON.stringify(data);
  }

  /**
   * Добавляет указанные данные в параметры переданного адреса страницы.
   * @param url Адрес страницы.
   * @param data Данные.
   */
  private setJsonToUrl(url: string, data: any) {
    const builder = new Url(url);
    const { query: previousQuery } = builder;
    let dataNormal = {};
    let dataWithArray: Record<string, (string | number)[]> = {};
    let dataAdds: string[] = [];
    let queryAdds = '';

    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        if (!Array.isArray(value)) {
          dataNormal = { ...dataNormal, [key]: value };
        } else {
          dataWithArray = {
            ...dataWithArray,
            [key]: value as (string | number)[],
          };
        }
      });
      if (Object.keys(dataWithArray).length) {
        Object.entries(dataWithArray).forEach(([key, values]) => {
          dataAdds = [...dataAdds, ...values.map((value) => `${key}=${value}`)];
        });
        queryAdds = dataAdds.join('&');
      }
    }

    const nextQuery = {
      ...previousQuery,
      ...dataNormal,
    };

    builder.set('query', nextQuery);
    const queryParam = Object.keys(dataNormal).length ? '&' : '?';
    const queryAddsFinal = queryAdds ? queryParam + queryAdds : '';
    const finalString = builder.toString() + queryAddsFinal;

    return finalString;
  }

  /**
   * Добавляет указанные данные с формы в параметры переданного адреса страницы.
   * @param url Адрес страницы.
   * @param data Данные с формы.
   */
  private setFormToUrl(url: string, data: FormData) {
    const params: Record<string, string> = {};

    const keys = Array.from(data.keys());
    const { length: keysCount } = keys;

    for (let i = 0; i < keysCount; i += 1) {
      const key = keys[i];
      const value = data.get(key);

      if (value != null && !(value instanceof File)) {
        params[key] = value;
      }
    }

    return this.setJsonToUrl(url, params);
  }

  /**
   * Выполняет запрос к указанной конечной точке API с переданными параметрами.
   * Отличается от метода `fetch` тем, что не подставляет в запрос токенов
   * авторизации.
   * @param method Метод HTTP.
   * @param endpoint Адрес конечной точки, на которую совершается запрос.
   * @param data Тело запроса (если есть).
   * @param headers Коллекция заголовков запроса.
   */
  private request = async (
    method: ApiMethod,
    endpoint: string,
    data?: Data,
    headers: Headers = {},
  ) => {
    const type: Type = data instanceof FormData ? 'form' : 'json';
    const url = this.getUrl(endpoint);

    let requestHeaders = { ...headers };
    let requestBody: any;
    let requestUrl = url;

    if (data instanceof FormData) {
      if (method === ApiMethod.GET || method === ApiMethod.HEAD) {
        requestUrl = this.setFormToUrl(requestUrl, data);
      } else {
        requestBody = data;
      }
    } else {
      requestHeaders = this.setJsonHeaders(requestHeaders);

      if (method === ApiMethod.GET || method === ApiMethod.HEAD) {
        requestUrl = this.setJsonToUrl(requestUrl, data);
      } else {
        requestBody = this.stringifyJson(data);
      }
    }

    const response = await fetch(requestUrl, {
      method,
      headers: requestHeaders,
      body: requestBody,
      mode: 'cors',
    });

    const responseBody = await response.text();
    let responseType: ResponseType = 'json';
    let responseData: any;

    try {
      responseData = responseBody ? JSON.parse(responseBody) : undefined;
    } catch (error) {
      responseType = 'text';
    }

    const { ok } = response;

    if (ok) {
      return responseData;
    }

    const { status } = response;

    throw new HttpError(
      type,
      method,
      url,
      data,
      headers,
      method,
      requestUrl,
      requestBody,
      requestHeaders,
      status,
      responseType,
      responseBody,
      responseData,
    );
  };

  /**
   * Возвращает копию переданной коллекции заголовков запроса с добавленными в
   * неё заголовками авторизации по указанному токену.
   * @param headers Исходная коллекция заголовков запроса.
   * @param token Токен авторизации.
   */
  private signHeaders(headers: Headers, token: string) {
    return { ...headers, [`Authorization`]: `Bearer ${token}` } as Headers;
  }

  /**
   * Обещание, которое вернёт новую пару токенов авторизации.
   */
  private tokensRenewing?: Promise<Tokens>;

  /**
   * Возвращает новую пару токенов авторизации.
   * @param refresh Токен, используемый для перевыпуска пары токенов.
   */
  private renewTokens(refresh: string) {
    let promise = this.tokensRenewing;

    if (promise) {
      return promise;
    }

    promise = (async () => {
      try {
        return await this.config.renewTokens(this.request, refresh);
      } finally {
        this.tokensRenewing = undefined;
      }
    })();

    this.tokensRenewing = promise;
    return promise;
  }

  /**
   * Выполняет авторизованный запрос к указанной конечной точке API с
   * переданными параметрами.
   * @param method Метод HTTP.
   * @param endpoint Адрес конечной точки, на которую совершается запрос.
   * @param data Данные, передаваемые в запросе.
   * @param headers Коллекция заголовков запроса.
   */
  private async fetch(
    method: ApiMethod,
    endpoint: string,
    data?: Data,
    headers: Headers = {},
  ) {
    let tokens = this.config.getTokens();

    if (!tokens) {
      return this.request(method, endpoint, data, headers);
    }

    if (tokens.access) {
      const nextHeaders = this.signHeaders(headers, tokens.access);

      try {
        return await this.request(method, endpoint, data, nextHeaders);
      } catch (error) {
        const isBadAccessToken =
          error instanceof HttpError && this.config.isBadAccessToken(error);

        if (!isBadAccessToken) {
          throw error;
        }
      }
    }

    try {
      tokens = await this.renewTokens(tokens.refresh);
    } catch (error) {
      const isBadRefreshToken =
        error instanceof HttpError && this.config.isBadRefreshToken(error);

      if (isBadRefreshToken) {
        this.config.onTokensExpire();
      }

      throw error;
    }

    const nextHeaders = this.signHeaders(headers, tokens.access as string);
    this.config.onTokensChange(tokens);

    return this.request(method, endpoint, data, nextHeaders);
  }

  /**
   * Выполняет HEAD-запрос к указанной конечной точке API.
   * @param endpoint Конечная точка API.
   * @param data Данные, передаваемые в запросе.
   * @param headers Коллекция заголовков.
   */
  public head(endpoint: string, data?: Data, headers: Headers = {}) {
    return this.fetch(ApiMethod.HEAD, endpoint, data, headers);
  }

  /**
   * Выполняет GET-запрос к указанной конечной точке API.
   * @param endpoint Конечная точка API.
   * @param data Данные, передаваемые в запросе.
   * @param headers Коллекция заголовков.
   */
  public get(endpoint: string, data?: Data, headers: Headers = {}) {
    return this.fetch(ApiMethod.GET, endpoint, data, headers);
  }

  /**
   * Выполняет PUT-запрос к указанной конечной точке API.
   * @param endpoint Конечная точка API.
   * @param data Данные, передаваемые в запросе.
   * @param headers Коллекция заголовков запроса.
   */
  public put(endpoint: string, data: Data = {}, headers: Headers = {}) {
    return this.fetch(ApiMethod.PUT, endpoint, data, headers);
  }

  /**
   * Выполняет POST-запрос к указанной конечной точке API.
   * @param endpoint Конечная точка API.
   * @param data Данные, передаваемые в запросе.
   * @param headers Коллекция заголовков запроса.
   */
  public post(endpoint: string, data: Data = {}, headers: Headers = {}) {
    return this.fetch(ApiMethod.POST, endpoint, data, headers);
  }

  /**
   * Выполняет PATCH-запрос к указанной конечной точке API.
   * @param endpoint Конечная точка API.
   * @param data Данные, передаваемые в запросе.
   * @param headers Коллекция заголовков запроса.
   */
  public patch(endpoint: string, data: Data = {}, headers: Headers = {}) {
    return this.fetch(ApiMethod.PATCH, endpoint, data, headers);
  }

  /**
   * Выполняет DELETE-запрос к указанной конечной точке API.
   * @param endpoint Конечная точка API.
   * @param data Данные, передаваемые в запросе.
   * @param headers Коллекция заголовков запроса.
   */
  public delete(endpoint: string, data: Data = {}, headers: Headers = {}) {
    return this.fetch(ApiMethod.DELETE, endpoint, data, headers);
  }
}
