/**
 * Метод HTTP.
 */
enum ApiMethod {
  HEAD = 'HEAD',

  GET = 'GET',

  PUT = 'PUT',

  DELETE = 'DELETE',

  POST = 'POST',

  PATCH = 'PATCH',
}

export default ApiMethod;
