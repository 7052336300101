import { SimplePaletteColorOptions, ThemeOptions } from '@material-ui/core';

import Palette from './Palette';

const primary = Palette.palette?.primary as SimplePaletteColorOptions;
const secondary = Palette.palette?.secondary as SimplePaletteColorOptions;

/**
 * Конфигурация кнопок Button.
 */
const MuiButton: ThemeOptions = {
  overrides: {
    MuiButton: {
      contained: {
        '&$disabled': {
          color: primary.contrastText,
          opacity: 0.5,
        },
      },

      containedPrimary: {
        '&:hover': {
          backgroundColor: primary.dark,
        },

        '&$disabled': {
          backgroundColor: primary.main,
        },
      },

      containedSecondary: {
        '&:hover': {
          backgroundColor: secondary.dark,
        },

        '&$disabled': {
          backgroundColor: secondary.main,
        },
      },

      containedSizeLarge: {
        padding: '19px 24px',
      },

      containedSizeSmall: {
        padding: '6px 14px',
      },

      iconSizeLarge: {
        '&>:first-child': {
          fontSize: 18,
        },
      },

      iconSizeMedium: {
        '&>:first-child': {
          fontSize: 17,
        },
      },

      iconSizeSmall: {
        '&>:first-child': {
          fontSize: 13,
        },
      },

      outlined: {
        padding: '7px 15px',

        '&:hover': {
          borderColor: 'currentColor',
        },

        '&$disabled': {
          borderColor: 'currentColor',
          opacity: 0.5,
        },
      },

      outlinedPrimary: {
        '&:hover': {
          backgroundColor: primary.background,
        },

        '&$disabled': {
          borderColor: 'currentColor',
          color: primary.main,
        },
      },

      outlinedSecondary: {
        '&:hover': {
          backgroundColor: secondary.background,
        },

        '&$disabled': {
          borderColor: 'currentColor',
          color: secondary.main,
        },
      },

      outlinedSizeLarge: {
        padding: '18px 23px',
      },

      outlinedSizeSmall: {
        padding: '5px 13px',
      },

      root: {
        letterSpacing: '0.2px',
        lineHeight: 1.214,
        padding: '8px 16px',
        textTransform: 'none',

        '&$disabled': {
          color: Palette.palette?.text?.disabled,
        },
      },

      sizeLarge: {
        letterSpacing: '0.5px',
        lineHeight: 1.2,
      },

      sizeSmall: {
        borderRadius: 4,
        letterSpacing: 'normal',
        lineHeight: 1.231,
      },

      text: {
        padding: '8px 16px',
      },

      textPrimary: {
        '&:hover': {
          backgroundColor: primary.background,
        },
      },

      textSizeLarge: {
        padding: '19px 24px',
      },

      textSizeSmall: {
        padding: '6px 14px',
      },
    },
  },
};

export default MuiButton;
