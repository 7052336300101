import { ThemeOptions } from '@material-ui/core';

/**
 * Конфигурация теней.
 */
const Shadows: ThemeOptions = {
  shadows: [
    'none',
    '0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.12)', // 1dp | Base
    '0 0 1px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)', // 2dp | Switch
    '0 1px 2px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.12), 0 1px 8px rgba(0, 0, 0, 0.1)', // 3dp | Button
    '0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.02), 0 1px 12px rgba(0, 0, 0, 0.12)', // 4dp | Appbar
    '0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.02), 0 1px 12px rgba(0, 0, 0, 0.12)',
    '0 3px 6px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.08), 0 1px 12px rgba(0, 0, 0, 0.04)', // 6dp | Floating Action Button (FAB)
    '0 3px 6px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.08), 0 1px 12px rgba(0, 0, 0, 0.04)',
    '0 4px 8px rgba(0, 0, 0, 0.12), 0 6px 12px rgba(0, 0, 0, 0.12), 0 1px 16px rgba(0, 0, 0, 0.12)', // 8dp | Popup
    '0 4px 8px rgba(0, 0, 0, 0.12), 0 6px 12px rgba(0, 0, 0, 0.12), 0 1px 16px rgba(0, 0, 0, 0.12)',
    '0 4px 8px rgba(0, 0, 0, 0.12), 0 6px 12px rgba(0, 0, 0, 0.12), 0 1px 16px rgba(0, 0, 0, 0.12)',
    '0 4px 8px rgba(0, 0, 0, 0.12), 0 6px 12px rgba(0, 0, 0, 0.12), 0 1px 16px rgba(0, 0, 0, 0.12)',
    '0 6px 12px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12), 0 1px 24px rgba(0, 0, 0, 0.12)', // 12dp | FAB (pressed)
    '0 6px 12px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12), 0 1px 24px rgba(0, 0, 0, 0.12)',
    '0 6px 12px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12), 0 1px 24px rgba(0, 0, 0, 0.12)',
    '0 6px 12px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12), 0 1px 24px rgba(0, 0, 0, 0.12)',
    '0 8px 16px rgba(0, 0, 0, 0.12), 0 12px 24px rgba(0, 0, 0, 0.12), 0 1px 32px rgba(0, 0, 0, 0.12)', // 16dp | NavDrawer
    '0 0 18px rgba(0, 0, 0, 0.16), 0 0 28px rgba(0, 0, 0, 0.16), 0 0 52px rgba(0, 0, 0, 0.16)', // 17dp | NavDrawer 2
    '0 8px 16px rgba(0, 0, 0, 0.12), 0 12px 24px rgba(0, 0, 0, 0.12), 0 1px 32px rgba(0, 0, 0, 0.12)',
    '0 8px 16px rgba(0, 0, 0, 0.12), 0 12px 24px rgba(0, 0, 0, 0.12), 0 1px 32px rgba(0, 0, 0, 0.12)',
    '0 8px 16px rgba(0, 0, 0, 0.12), 0 12px 24px rgba(0, 0, 0, 0.12), 0 1px 32px rgba(0, 0, 0, 0.12)',
    '0 8px 16px rgba(0, 0, 0, 0.12), 0 12px 24px rgba(0, 0, 0, 0.12), 0 1px 32px rgba(0, 0, 0, 0.12)',
    '0 8px 16px rgba(0, 0, 0, 0.12), 0 12px 24px rgba(0, 0, 0, 0.12), 0 1px 32px rgba(0, 0, 0, 0.12)',
    '0 8px 16px rgba(0, 0, 0, 0.12), 0 12px 24px rgba(0, 0, 0, 0.12), 0 1px 32px rgba(0, 0, 0, 0.12)',
    '0 12px 24px rgba(0, 0, 0, 0.12), 0 16px 32px rgba(0, 0, 0, 0.12), 0 1px 48px rgba(0, 0, 0, 0.12)', // 24dp | Dialog
  ],
};

export default Shadows;
