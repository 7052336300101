import { ThemeOptions } from '@material-ui/core';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import deepmerge from 'deepmerge';

import AsideContent from './AsideContent';
import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiCssBaseline from './MuiCssBaseline';
import MuiFilledInput from './MuiFilledInput';
import MuiFormHelperText from './MuiFormHelperText';
import MuiIconButton from './MuiIconButton';
import MuiInputAdornment from './MuiInputAdornment';
import MuiInputLabel from './MuiInputLabel';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiSelect from './MuiSelect';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiTabs from './MuiTabs';
import MuiToolbar from './MuiToolbar';
import MuiTooltip from './MuiTooltip';
import Palette from './Palette';
import Shadows from './Shadows';
import Shape from './Shape';
import Typography from './Typography';

/**
 * Возвращает глобальную тему Material UI, используемую в приложении.
 */
export default function createTheme() {
  let options: ThemeOptions = {};

  options = deepmerge(options, MuiCssBaseline);
  options = deepmerge(options, AsideContent);
  options = deepmerge(options, Typography);
  options = deepmerge(options, MuiToolbar);
  options = deepmerge(options, MuiButton);
  options = deepmerge(options, MuiCheckbox);
  options = deepmerge(options, MuiFormHelperText);
  options = deepmerge(options, MuiFilledInput);
  options = deepmerge(options, MuiIconButton);
  options = deepmerge(options, MuiInputAdornment);
  options = deepmerge(options, MuiInputLabel);
  options = deepmerge(options, MuiMenu);
  options = deepmerge(options, MuiMenuItem);
  options = deepmerge(options, MuiSelect);
  options = deepmerge(options, MuiTableSortLabel);
  options = deepmerge(options, MuiTabs);
  options = deepmerge(options, MuiTab);
  options = deepmerge(options, MuiTableCell);
  options = deepmerge(options, MuiTooltip);
  options = deepmerge(options, Palette);
  options = deepmerge(options, Shadows);
  options = deepmerge(options, Shape);

  return createMuiTheme(options);
}
