import SessionExpiredDialog from 'components/dialogs/SessionExpiredDialog';
import StorageKey from 'constants/StorageKey';
import React, {
  createContext,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ApiService from 'services/ApiService';
import { User, UserRole } from 'types/entities/User';
import Tokens from 'types/Tokens';
/**
 * Значение контекста сессии.
 */
type SessionContextValue = Readonly<{
  /**
   * Определяет, инициализирован ли контекст сессии.
   */
  readonly isReady: boolean;

  /**
   * Определяет, прошёл ли пользователь аутентификацию.
   */
  readonly isAuthenticated: boolean;

  /**
   * Определяет, происходит ли процедура выхода из системы.
   */
  readonly isLoggingOut: boolean;

  /**
   * Определяет, является ли пользователь администратором.
   */
  readonly isAdmin: boolean;

  /**
   * Сервис клиента API.
   */
  readonly client: ApiService;

  /**
   * Текущий пользователь.
   */
  readonly user: User | undefined;

  /**
   * Авторизует контекст сессии для использования токенов аутентификации.
   */
  authorize(tokens: Tokens): void;

  /**
   * Уничтожает контейнер защищённой сессии, завершая её.
   */
  logout(): void;
}>;

/**
 * Контекст контейнера сессии.
 */
const SessionContext = createContext<SessionContextValue>(
  undefined! as SessionContextValue,
);

/**
 * Возвращает провайдер контекста контейнера сессии.
 */
export const SessionContextProvider: FC = ({ children }) => {
  const tokens = useRef<Tokens>();
  const [initialized, setInitialized] = useState(false);
  const [expired, setExpired] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const accessToken =
      typeof window !== 'undefined'
        ? localStorage.getItem(StorageKey.ACCESS_TOKEN)
        : undefined;
    const refreshToken =
      typeof window !== 'undefined'
        ? localStorage.getItem(StorageKey.REFRESH_TOKEN)
        : undefined;

    tokens.current =
      accessToken && refreshToken
        ? {
            access: accessToken,
            refresh: refreshToken,
          }
        : undefined;

    setInitialized(true);
  }, []);

  const service = useRef(
    new ApiService({
      getTokens: () => tokens.current,
      onTokensChange: (newTokens) => {
        tokens.current = newTokens;
        localStorage.setItem(StorageKey.ACCESS_TOKEN, newTokens.access);
        localStorage.setItem(StorageKey.REFRESH_TOKEN, newTokens.refresh);
      },
      onTokensExpire: () => {
        tokens.current = undefined;
        localStorage.removeItem(StorageKey.ACCESS_TOKEN);
        localStorage.removeItem(StorageKey.REFRESH_TOKEN);

        setExpired(true);
      },
    }),
  );

  const { data: user, refetch } = useQuery(
    'user',
    () => service.current.fetchUserInfo(),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(tokens.current) && initialized,
    },
  );

  const authenticated = !loggedOut && initialized && Boolean(tokens.current);

  function handleCloseExpired() {
    setExpired(false);
  }

  const value = useMemo<SessionContextValue>(
    () => ({
      isReady: authenticated ? Boolean(user) : initialized,
      isAuthenticated: authenticated,
      isLoggingOut: loggedOut,
      isAdmin: user?.role === UserRole.Owner || user?.role === UserRole.Admin,
      client: service.current,
      user,

      authorize(newTokens) {
        localStorage.setItem(StorageKey.ACCESS_TOKEN, newTokens.access);
        localStorage.setItem(StorageKey.REFRESH_TOKEN, newTokens.refresh);
        tokens.current = newTokens;
        refetch();

        setLoggedOut(false);
      },

      logout() {
        localStorage.removeItem(StorageKey.ACCESS_TOKEN);
        localStorage.removeItem(StorageKey.REFRESH_TOKEN);
        tokens.current = undefined;

        // Invalidate all the client cache due to client logging out.
        queryClient.clear();

        setLoggedOut(true);
      },
    }),
    [authenticated, initialized, loggedOut, user, refetch, queryClient],
  );

  return (
    <SessionContext.Provider value={value}>
      <SessionExpiredDialog active={expired} onClose={handleCloseExpired} />
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContext;
