/**
 * Регулярные выражения, используемые для разбора строковых значений, которые
 * должны иметь фиксированный формат (такие как номер телефона, код
 * подтверждения и прочее).
 */
const FormatRegexp = {
  /**
   * Номер телефона в человекочитаемом формате (например, "+7 (987) 654 3210").
   */
  PRETTY_PHONE: /^\+(\d{1})\s\((\d{3})\)\s(\d{3})\s(\d{4})$/,

  /**
   * Номер телефона в системном формате (например, "79876543210").
   */
  SYSTEM_PHONE: /^(\d{1})(\d{3})(\d{3})(\d{4})$/,

  /**
   * Код подтверждения в человекочитаемом формате (например, "123-456").
   */
  PRETTY_CODE: /^(\d{3})-(\d{3})$/,

  /**
   * Код подтверждения в системном формате (например, "123456").
   */
  SYSTEM_CODE: /^(\d{3})(\d{3})$/,

  /**
   * Дата в человекочитаемом формате (к примеру, "31.12.2020").
   */
  PRETTY_DATE: /^(\d{2})\.(\d{2})\.([1-2]\d{3})$/,

  /**
   * Дата в человекочитаемом формате (к примеру, "31.12.2020 16:50").
   */
  PRETTY_DATE_WITH_TIME: /^(\d{2})\.(\d{2})\.([1-2]\d{3})\s(\d{2}):(\d{2})$/,

  /**
   * Дата в системном формате (к примеру, "2020-12-31").
   */
  SYSTEM_DATE: /^([1-2]\d{3})-(\d{2})-(\d{2})$/,

  /**
   * Дата в системном формате (к примеру, "2020-12-31 16:50:02").
   */
  SYSTEM_DATE_WITH_TIME:
    /^([1-2]\d{3})-(\d{2})-(\d{2})\s(\d{2}):(\d{2})(:(\d{2}))?$/,
};

export default FormatRegexp;
