import ApiEndpoint from 'constants/ApiEndpoint';
import ApiMethod from 'constants/ApiMethod';
import HttpError from './HttpError';
import ApiHelper from 'helpers/ApiHelper';
import RouteHelper from 'helpers/RouteHelper';
import FilterParam from 'types/FilterParam';
import PagingOptions from 'types/PagingOptions';
import Tokens from 'types/Tokens';
import { AddressBureau } from 'types/entities/AddressBureau';
import { Attachment, AttachmentType } from 'types/entities/Attachment';
import { Payment } from 'types/entities/Payment';
import { Employee } from 'types/entities/Employee';
import { Print } from 'types/entities/Print';
import { Jurisdiction } from 'types/entities/Jurisdiction';
import { Debt } from 'types/entities/Debt';
import { Debtor } from 'types/entities/Debtor';
import { StateDuty } from 'types/entities/StateDuty';
import { Bankruptcy } from 'types/entities/Bankruptcy';
import { ContractItem } from 'types/entities/ContractItem';
import { Contract } from 'types/entities/Contract';
import { HistoryItem } from 'types/entities/HistoryItem';
import { StateDutyItem } from 'types/entities/StateDutyItem';
import DateHelper from 'helpers/DateHelper';
import { Task, TaskType } from 'types/entities/Task';
import { TaskItem } from 'types/entities/TaskItem';
import { LoginFactor } from 'types/entities/LoginFactor';
import { RecoverFactor } from 'types/entities/RecoverFactor';
import { User, UserRole } from 'types/entities/User';

import SortingParams from '../../types/SortingParams';
import HttpClient from './HttpClient';
import { CourtComment } from 'types/entities/CourtComment';
import { LegalEntity } from 'types/entities/LegalEntity';
import { CourtEntity } from 'types/entities/CourtEntity';
import { CourtRegion } from 'types/entities/CourtRegion';

/**
 * Функция, используемая для отправки запросов к API.
 */
type Fetch = Parameters<
  ConstructorParameters<typeof HttpClient>[0]['renewTokens']
>[0];

/**
 * Коллекция настроек сервиса.
 */
interface Config {
  /**
   * Возвращает токен авторизации текущего пользователя в API.
   */
  getTokens?: () => Tokens | undefined;

  /**
   * Задаёт новую пару токенов авторизации в API или удаляет их, если в функцию
   * передано `undefined`.
   * @param tokens Пара токенов или `undefined`, если пару токенов следует
   * удалить.
   */
  onTokensChange?: (tokens: Tokens) => void;

  /**
   * Вызывается, когда сервер отметил как истёкшую текущую пару токенов.
   */
  onTokensExpire?: () => void;
}

/**
 * Содержит низкоуровневые методы для работы с API.
 */
export default class ApiService {
  /**
   * Настройки сервиса.
   */
  private readonly config: Config;

  /**
   * Клиент HTTP, с помощью которого выполняются запросы к API.
   */
  private client: HttpClient;

  /**
   * Создаёт экземпляр сервиса.
   * @param config Настройки сервиса.
   */
  public constructor(config: Config = {}) {
    this.config = config;

    const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

    if (!baseUrl) {
      throw new Error(`Expect 'env.NEXT_PUBLIC_API_BASE_URL' to be defined`);
    }

    this.client = new HttpClient({
      baseUrl,
      onTokensChange: this.handleTokensChange,
      onTokensExpire: this.handleTokensExpire,
      isBadRefreshToken: this.isBadRefreshToken,
      isBadAccessToken: this.isBadAccessToken,
      renewTokens: this.renewTokens,
      getTokens: this.getTokens,
    });
  }

  /**
   * Возвращает токен авторизации текущего пользователя или `{}`, если
   * пользователь не прошёл аутентификацию.
   */
  private getTokens = () => {
    return this.config.getTokens?.();
  };

  /**
   * Перевыпускает пару токенов авторизации, используя Refresh Token из
   * предыдущей пары.
   * @param fetch Функция, используемая для отправки запроса к API.
   * @param refresh Токен для перевыпуска.
   */
  private renewTokens = async (fetch: Fetch, refresh: string) => {
    const response = await fetch(
      ApiMethod.POST,
      ApiEndpoint.RENEW_AUTH_TOKENS,
      {
        refresh_token: refresh,
      },
    );

    return ApiHelper.parseTokens(response);
  };

  /**
   * Возвращает `true`, если указанная ошибка, произошедшая при перевыпуске
   * пары токенов авторизации, сообщает о том, что Refresh Token не валиден
   * или истёк.
   * @param error Ошибка.
   */
  private isBadRefreshToken = (error: HttpError) => {
    return error.responseStatus === 400;
  };

  /**
   * Возвращает `true`, если указанная ошибка сообщает о том, что Access Token
   * не валиден или истёк.
   * @param error Ошибка.
   */
  private isBadAccessToken = (error: HttpError) => {
    return error.responseStatus === 401 || error.responseStatus === 403;
  };

  /**
   * Обрабатывает перевыпуск пары токенов авторизации.
   * @param tokens Новая пара токенов.
   */
  private handleTokensChange = (tokens: Tokens) => {
    this.config.onTokensChange?.(tokens);
  };

  /**
   * Обрабатывает истечение срока жизни пары токенов авторизации.
   */
  private handleTokensExpire = () => {
    this.config.onTokensExpire?.();
  };

  /**
   * Отправляет на сервер реквизиты для входа пользователя в систему и
   * возвращает сведения о созданной сессии двухфакторной аутентификации.
   * @param username Имя пользователя.
   * @param password Пароль.
   */
  public async submitLoginCredentials(
    username: string,
    password: string,
  ): Promise<LoginFactor | Tokens> {
    const response = await this.client.post(
      ApiEndpoint.SUBMIT_LOGIN_CREDENTIALS,
      {
        factor_value: password,
        login: username,
      },
    );

    if (response.access_token) {
      return {
        access: response.access_token,
        refresh: response.refresh_token,
      };
    }

    return {
      factorID: response.factor_id,
      factorName: response.factor_name,
      factorReceiver: response.factor_receiver,
      factorLogin: username,
      sessionToken: response.session_token,
    };
  }

  /**
   * Запрашивает у сервера повторную отправку кода подтверждения входа в
   * систему на телефон пользователя.
   * @param token Токен, полученный от сервера во время отправки реквизитов
   * для входа.
   * @param username Имя пользователя, совершающего вход.
   */
  public async requestLoginConfirmationCode(token: string, username: string) {
    await this.client.post(ApiEndpoint.RESEND_LOGIN_CONFIRMATION_CODE, {
      session_token: token,
      login: username,
    });
  }

  /**
   * Отправляет на сервер код подтверждения входа, полученный в СМС, и
   * возвращает токен авторизации. Если был отправлен неверный код, выбрасывает
   * @param token Токен, полученный от сервера во время отправки реквизитов для
   * входа.
   * @param username Имя пользователя, который входит в систему.
   * @param code Код подтверждения из СМС.
   * код подтверждения.
   */
  public async submitLoginConfirmationCode(
    token: string,
    username: string,
    code: string,
  ) {
    const response = await this.client.post(
      ApiEndpoint.SUBMIT_LOGIN_CONFIRMATION_CODE,
      {
        session_token: token,
        login: username,
        factor_value: code,
      },
    );

    return ApiHelper.parseTokens(response);
  }

  /**
   * Отправляет на сервер номер телефона пользователя, чтобы начать процесс
   * восстановления пароля, и возвращает токен, который нужно будет отправлять
   * на каждом шаге восстановления пароля.
   * @param phone Номер телефона.
   */
  public async submitRecoveryPhone(phone: string): Promise<RecoverFactor> {
    return {
      factorReceiver: phone,
      sessionToken: 'FAKE_TOKEN',
    };
  }

  /**
   * Отправляет на сервер код подтверждения восстановления пароля, полученный
   * в СМС.
   * @param token Токен, полученный от сервера во время отправки реквизитов для
   * восстановления пароля.
   * @param code Код подтверждения из СМС.
   */
  public async submitRecoveryConfirmationCode(_token: string, code: string) {
    if (code !== '123456') {
      throw new Error('invalid');
    }

    return {
      sessionToken: 'FAKE_TOKEN',
    };
  }

  /**
   * Отправляет на сервер новый пароль пользователя и возвращает токен
   * авторизации в системе.
   * @param token Токен операции восстановления пароля.
   * @param password Новый пароль.
   */
  public async submitRecoveryPassword(token: string, password: string) {
    Boolean(token);
    Boolean(password);
  }

  /**
   * Загружает с сервера сведения о текущем пользователе.
   */
  public async fetchUserInfo(): Promise<User> {
    const url = RouteHelper.compile(ApiEndpoint.GET_USER_INFO);
    const response = await this.client.get(url);

    return {
      id: response.payload.uuid,
      patronymicName: response.payload.patronymic,
      firstName: response.payload.first_name,
      lastName: response.payload.last_name,
      avatar: '/photo.svg',
      role: UserRole.Owner,
    };
  }

  /**
   * Загружает с сервера договоры, соответствующие параметрам.
   * @param pagingParams Диапазон результатов.
   * @param filteringParams Критерии фильтрации.
   * @param sortingParams Критерии сортировки.
   */
  public async fetchContracts(
    pagingParams: PagingOptions,
    filteringParams: FilterParam[],
    sortingParams: SortingParams,
  ) {
    const { offset, limit } = pagingParams;
    const filterOptions = ApiHelper.remapFilterParams(filteringParams);
    const { field, order } = sortingParams;

    let params = {
      offset,
      limit,
      ...filterOptions,
    };

    if (field && order) {
      params = { ...params, [`order[${field}]`]: order };
    }

    const response = await this.client.get(
      ApiEndpoint.GET_CONTRACTS_COLLECTION,
      params,
    );

    const items: ContractItem[] = response.payload.map((contract: any) => ({
      id: contract.uuid,
      number: contract.number,
      status: contract.status,
      debtDays: Number(contract.late_days),
      loanAmount: contract.body_amount,
      debtAmount: contract.body_left_amount,
      loanDate: contract.issue_date,
      loanType: contract.type,
      employeeID: contract.employee?.uuid,
      region: contract.client_profile?.address_registration_region,
      debtorFirstName: contract.client_profile?.first_name,
      debtorLastName: contract.client_profile?.last_name,
      debtorPatronymicName: contract.client_profile?.patronymic,
      favorite: Boolean(contract.meta?.favorite),
      paidAmount: contract.amount_paid_total,
      loanComment: contract.important_info,
      courtSpecial: Boolean(contract.jurisdiction?.court_info?.is_special),
      courtComments:
        contract.jurisdiction?.court_info?.court_comments?.map(
          (comment: any) => ({
            isShared: comment.is_shared,
            textShort: comment.short_text,
            text: comment.text,
            uuid: comment.uuid,
          }),
        ) ?? [],
      claimant: contract.claimant_company?.short_name,
    }));

    const count: number = response.meta.total_count;

    return {
      items,
      count,
    };
  }

  /**
   * Загружает с сервера список сотрудниктов.
   */
  public async fetchEmployees(
    limit: string,
    sortingParams?: SortingParams,
  ): Promise<Employee[]> {
    let params = { limit };
    if (sortingParams) {
      const { field, order } = sortingParams;
      params = { ...params, [`order[${field}]`]: order };
    }

    const response = await this.client.get(
      ApiEndpoint.GET_EMPLOYEES_LIST,
      params,
    );

    return response.payload.map(ApiHelper.mapEmployee);
  }

  /**
   * Исключить из проверки банкротства.
   * @param contractId Идентификатор договоров.
   * @param action Действие вида: true - Исключить из проверки банкротства / false - Включить
   * проверку банкротства.
   */
  public async changeBlockBankruptcyInspection(id: string, action: boolean) {
    const url = RouteHelper.compile(ApiEndpoint.EDIT_LOAN_BANKRUPTCY_BLOCK, {
      id,
    });

    await this.client.post(url, {
      is_blocked: action,
    });
  }

  /**
   * Ручное редактирование данных в проверке банкротства.
   * @param contractId Идентификатор договоров.
   * @param field Имя поля.
   * @param values Значения полей.
   */
  public async changeBankruptcyInspection(contractID: string, values: any) {
    const url = RouteHelper.compile(ApiEndpoint.EDIT_LOAN_BANKRUPTCY, {
      id: contractID,
    });

    await this.client.patch(url, values);
  }

  /**
   * Получить статус о блокировке на проверку банкротства.
   * @param id Идентификатор договора.
   */
  public async getBlockBankruptcyInspection(id: string) {
    const url = RouteHelper.compile(
      ApiEndpoint.GET_LOAN_BANKRUPTCY_BLOCK_STATUS,
      { id },
    );
    const response = await this.client.get(url);

    return response.payload.is_blocked as boolean;
  }

  /**
   * Загружает с сервера данные о последней проверке займа на банкротство.
   * @param id Идентификатор договора.
   */
  public async fetchContractBankruptcyHistory(
    id: string,
  ): Promise<Bankruptcy | undefined> {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_BANKRUPTCY, { id });
    let response: any;

    try {
      response = await this.client.get(url);
    } catch (error) {
      if (ApiHelper.isError(error, 400)) {
        return undefined;
      }

      throw error;
    }

    return {
      status: response.payload.status,
      stage: response.payload.stage,
      stage_date: response.payload.stage_date
        ? new Date(response.payload.stage_date)
        : undefined,
      case_number: response.payload.case_number,
      bankrupt_date: response.payload.bankrupt_date
        ? new Date(response.payload.bankrupt_date)
        : undefined,
      updated_at: response.payload.updated_at
        ? new Date(response.payload.updated_at)
        : undefined,
    };
  }

  /**
   * Изменяет данные договора.
   * @param contractIds Идентификаторы договоров.
   */
  public async submitContractChange(
    values: Record<string, any>,
    ...contractIDs: string[]
  ) {
    for (const contractID of contractIDs) {
      const url = RouteHelper.compile(ApiEndpoint.EDIT_LOAN_INFO, {
        id: contractID,
      });

      await this.client.patch(url, values);
    }
  }

  /**
   * Загружает с сервера договоры на оплату ГП.
   * @param type Тип загружаемого списка.
   */
  public async fetchGosfee(
    pagingParams: PagingOptions,
    filteringParams: FilterParam[],
    sortingParams: SortingParams,
  ) {
    const { offset, limit } = pagingParams;
    const filterOptions = ApiHelper.remapFilterParams(filteringParams);
    const { field, order } = sortingParams;

    const url = RouteHelper.compile(ApiEndpoint.GET_GOSFEE);

    const response = await this.client.get(url, {
      offset,
      limit,
      ...filterOptions,
      [`order[${field}]`]: order,
    });

    const items: StateDutyItem[] = response.payload.map((request: any) => ({
      id: request.uuid,
      contractID: request.loan.uuid,
      contractNumber: request.loan.number,
      status: request.status,
      createdByEmployeeID: request.created_by_uuid,
      approvalRequired: Boolean(request.approval_required),
      responsibleEmployeeID: request.responsible_uuid,
      paymentOrderID: request.payment_order_uuid,
      statusComment: request.status_comment,
      createdAt: new Date(request.created_at),
      approvedAt: request.approval_decision_at
        ? new Date(request.approval_decision_at)
        : undefined,
      finishedAt: request.finished_at
        ? new Date(request.finished_at)
        : undefined,
      paymentAmount: Number(request.payment_order_request.payment_amount),
      debtAmount: Number(request.debt_calculation.debt_amount),
    }));

    const count: number = response.meta.total_count;

    return {
      items,
      count,
    };
  }

  /**
   * Решение по принятию оплаты ГП.
   * @param id Идентифкатор запроса.
   */
  public async fetchActionApproveGosfee(id: string) {
    const url = RouteHelper.compile(ApiEndpoint.CHANGE_GOSFEE_ACTION, {
      id,
    });

    await this.client.patch(url, { approved: true });
  }

  /**
   * Решение по отмене оплаты ГП.
   * @param id Идентифкатор запроса.
   * @param comment Комментарий.
   */
  public async fetchActionRejectGosfee(id: string, comment: string) {
    const url = RouteHelper.compile(ApiEndpoint.CHANGE_GOSFEE_ACTION, {
      id,
    });

    await this.client.patch(url, { approved: false, comment });
  }

  /**
   * Загружает с сервера основную информацию по договору, которая отображается в карточке заемщика.
   */
  public async fetchContractGosfee(id: string): Promise<StateDuty> {
    const urlGosfee = RouteHelper.compile(ApiEndpoint.GET_LOAN_GOSFEE, { id });
    const response = await this.client.get(urlGosfee);

    const value = response.payload;

    return {
      status: value?.status,
      status_comment: value?.payment_request?.status_comment,
    };
  }

  /**
   * Загружает с сервера основные данные по договору займа.
   * @param id Идентификатор договора.
   */
  public async fetchContractInfo(id: string): Promise<Contract> {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_INFO, { id });
    const response = await this.client.get(url);

    return {
      id: response.payload.uuid,
      number: response.payload.number,
      status: response.payload.status,
      loanAmount: Number(response.payload.body_amount),
      loanDate: response.payload.issue_date,
      loanEndDate: DateHelper.getDateInFuture(
        String(response.payload.issue_date),
        Number(response.payload.period),
      ),
      debtAmount: Number(response.payload.body_left_amount),
      paidAmount: Number(response.payload.amount_paid_total),
      debtDays: Number(response.payload.late_days),
      debtorPatronymicName: response.payload.client_profile.patronymic,
      debtorFirstName: response.payload.client_profile.first_name,
      debtorLastName: response.payload.client_profile.last_name,
      employee: response.payload.employee
        ? ApiHelper.mapEmployee(response.payload.employee)
        : undefined,
      region:
        response.payload.client_profile?.address_registration_region ?? '—',
      favorite: Boolean(response.payload.meta.favorite),
      loanType: response.payload.type,
      loanIssueMethod: response.payload.issue_method,
      creditCompany: response.payload.credit_company.name,
      serviceCompany: '',
      loanComment: response.payload.important_info,
      loanInterestRatePerDay: Number(response.payload.interest_rate_per_day),
      loanInterestRatePerYear: Number(response.payload.interest_rate_per_year),
      loanInterestAmount: Number(response.payload.percents_amount),
      loanPeriod: Number(response.payload.period),
      loanPenaltyAmount: Number(response.payload.penalty_left_amount),
      loanInterestAmountLeft: Number(response.payload.percents_left_amount),
      loanPenaltyRate: Number(response.payload.late_percents_left_amount),
      loanAmountLeft: Number(response.payload.amount_left_total),
      loanAmountLeftSchedule: Number(response.payload.amount_left_schedule),
      productName: response.payload.product_alias_translate,
      addressesBureau:
        response.payload.bki_info?.addresses?.map((address: any) => ({
          address: address.address ?? '',
          date: new Date(address.date),
          source: address.source,
          type: address.type,
        })) ?? [],
    };
  }

  /**
   * Загружает с сервера историю договора.
   * @param id Идентификатор договора.
   */
  public async fetchContractHistory(id: string) {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_HISTORY, { id });
    const response = await this.client.get(url, {
      id,
    });

    return ApiHelper.mapContractHistory(response);
  }

  /**
   * Загружает с сервера историю платежей по договору.
   * @param id Идентификатор договора.
   */
  public async fetchContractPayments(id: string): Promise<Payment[]> {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_PAYMENTS, { id });
    const response = await this.client.get(url, {
      id,
    });

    return response.payload.map((payment: any) => ({
      id: payment.uuid,
      loan: payment.number,
      amount: Number(payment.amount_total),
      amountPaid: Number(payment.amount_paid_total),
      amountLeft: Number(payment.amount_left_total),
      created: new Date(payment.created_at),
      paymentDate: new Date(payment.payment_date),
    }));
  }

  /**
   * Загружает с сервера аттачменты по договору.
   * @param id Идентификатор договора.
   */
  public async fetchContractAttachments(id: string): Promise<Attachment[]> {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_ATTACHMENTS, { id });
    const response = await this.client.get(url);

    return response.payload.map(ApiHelper.mapAttachment);
  }

  /**
   * Отправляет на сервер загружаемый файл
   */
  public async sendUploadAttachment(
    contractId: string,
    file: File,
    type: keyof typeof AttachmentType,
  ) {
    const url = RouteHelper.compile(ApiEndpoint.SEND_LOAN_ATTACHMENT, {
      id: contractId,
    });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    const uploadedAttachment = await this.client.post(url, formData);

    return ApiHelper.mapAttachment(uploadedAttachment.payload);
  }

  /**
   * Удаляет аттачмент с сервера
   */
  public async deleteAttachment(id?: string) {
    if (!id) {
      return;
    }

    const url = RouteHelper.compile(ApiEndpoint.DELETE_LOAN_ATTACHMENT, {
      id,
    });

    await this.client.delete(url);
  }

  /**
   * Загружает с сервера персональную информацию о заёмщике.
   * @param id Идентификатор договора.
   */
  public async fetchContractDebtor(id: string): Promise<Debtor> {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_DEBTOR, { id });
    const response = await this.client.get(url);

    return {
      id: response.payload.uuid,
      passportSeries: response.payload.passport?.series,
      passportNumber: response.payload.passport?.number,
      passportIssuer: response.payload.passport?.issued_by,
      passportIssuedDate: response.payload.passport?.issue_date
        ? new Date(response.payload.passport.issue_date)
        : undefined,
      passportIssuerCode: response.payload.passport?.department_code,
      firstName: response.payload.passport?.first_name,
      lastName: response.payload.passport?.last_name,
      patronymicName: response.payload.passport?.patronymic,
      gender: response.payload.passport?.gender,
      birthDate: response.payload.passport?.date_of_birth
        ? new Date(response.payload.passport.date_of_birth)
        : undefined,
      birthPlace: response.payload.passport?.place_of_birth,
      addresses:
        response.payload.addresses?.map((address: any) => ({
          id: address.uuid,
          type: address.type,
          country: address.country,
          region: address.region,
          area: address.area,
          city: address.locality,
          street: address.street,
          house: address.house,
          apartment: address.apartment,
          postcode: address.post_code,
        })) ?? [],
      inn: response.payload.personal_info?.inn,
      snils: response.payload.personal_info?.ssn,
      timeZone: response.payload.personal_info?.time_zone,
      email: response.payload.personal_info?.email,
      otherLoans: response.payload.personal_info?.other_loans,
      maritalStatus: response.payload.family_info?.martial_status,
      familyMembersNumber: response.payload.family_info?.person_count,
      childrenNumber: response.payload.family_info?.children_count,
      livesWithParents: Boolean(
        response.payload.family_info?.lives_with_parents,
      ),
      jobStatus: response.payload.work_info?.status,
      jobPosition: response.payload.work_info?.position,
      averageSalary: response.payload.work_info?.salary,
      additionalIncome: response.payload.work_info?.additional_income,
      payoutDay: Number(response.payload.work_info?.salary_day) || undefined,
      employerName: response.payload.work_info?.employer,
      employerAddress: response.payload.work_info?.address,
      employerPhone: response.payload.work_info?.phone,
      note: response.payload.work_info?.note,
    };
  }

  /**
   * Сохраняет данные в анкете заёмщика.
   * @param id Идентификатор договора.
   * @param fields Данные для отправки на сервер.
   */
  public async saveDebtorData(id: string, fields: Record<string, any>) {
    const url = RouteHelper.compile(ApiEndpoint.EDIT_LOAN_DEBTOR, { id });

    await this.client.patch(url, fields);
  }

  /**
   * Сохраняет адрес в анкете заёмщика.
   * @param id Идентификатор договора.
   * @param addressId Идентификатор адреса.
   * @param fields Данные для отправки на сервер.
   */
  public async saveDebtorAddress(
    id: string,
    addressId: string,
    fields: Record<string, any>,
  ) {
    const url = RouteHelper.compile(ApiEndpoint.EDIT_LOAN_DEBTOR_ADDRESS, {
      id,
      addressId,
    });

    await this.client.patch(url, fields);
  }

  /**
   * Загружает с сервера последнее определение подсудности.
   * @param id Идентификатор договора.
   */
  public async fetchContractJurisdictionLastQuery(id: string) {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_JURISDICTION_QUERIES, {
      id,
    });
    const response = await this.client.get(url, {
      limit: 1,
      'order[created_at]': 'DESC',
    });

    return ApiHelper.mapContractJurisdictionLastQuery(response);
  }

  /**
   * Загружает с сервера настройки определения подсудности.
   * @param id Идентификатор договора.
   */
  public async fetchContractJurisdictionSettings(id: string) {
    const url = RouteHelper.compile(
      ApiEndpoint.GET_LOAN_JURISDICTION_SETTINGS,
      {
        id,
      },
    );
    const response = await this.client.get(url);

    return {
      autoCheckOff: response.payload?.jurisdiction_changes_blocked ?? false,
    };
  }

  /**
   * Сохраняет настройки поиска подсудности.
   * @param id Идентификатор договора.
   * @param data Данные для отправки на сервер.
   */
  public async saveJurisdictionSettings(id: string, data: Record<string, any>) {
    const url = RouteHelper.compile(
      ApiEndpoint.EDIT_LOAN_JURISDICTION_SETTINGS,
      { id },
    );

    await this.client.patch(url, data);
  }

  /**
   * Загружает с сервера данные о подсудности.
   * @param id Идентификатор договора.
   */
  public async fetchContractJurisdiction(id: string): Promise<Jurisdiction> {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_JURISDICTION_DATA, {
      id,
    });

    const response = await this.client.get(url);

    return {
      status: response.payload.status,
      courtInfo: {
        name: response.payload.jurisdiction?.court?.name,
        code: response.payload.jurisdiction?.court?.code,
        type: response.payload.jurisdiction?.court?.type,
        email: response.payload.jurisdiction?.court?.emails,
        phones: response.payload.jurisdiction?.court?.phones,
        site: response.payload.jurisdiction?.court?.website_url,
        fullAddress:
          response.payload.jurisdiction?.court?.address?.full_address,
        postcode: response.payload.jurisdiction?.court?.address?.post_code,
        region: response.payload.jurisdiction?.court?.address?.region,
        city: response.payload.jurisdiction?.court?.address?.locality,
        street: response.payload.jurisdiction?.court?.address?.street,
        house: response.payload.jurisdiction?.court?.address?.house,
        special: response.payload.jurisdiction?.court_info?.is_special,
        comments: response.payload.jurisdiction?.court_info?.comments ?? [],
      },
      courtIfns: {
        fullAddress:
          response.payload.jurisdiction?.court_federal_treasury?.address
            ?.full_address,
        postcode:
          response.payload.jurisdiction?.court_federal_treasury?.address
            ?.post_code,
        region:
          response.payload.jurisdiction?.court_federal_treasury?.address
            ?.region,
        city: response.payload.jurisdiction?.court_federal_treasury?.address
          ?.locality,
        street:
          response.payload.jurisdiction?.court_federal_treasury?.address
            ?.street,
        house:
          response.payload.jurisdiction?.court_federal_treasury?.address?.house,
      },
      courtPaymentDetails: {
        receiver: response.payload.jurisdiction?.court_federal_treasury?.name,
        bank: response.payload.jurisdiction?.court_federal_treasury?.bank_name,
        account:
          response.payload.jurisdiction?.court_federal_treasury?.bank_account,
        bik: response.payload.jurisdiction?.court_federal_treasury?.bik,
        kpp: response.payload.jurisdiction?.court_federal_treasury?.kpp,
        korAccount:
          response.payload.jurisdiction?.court_federal_treasury
            ?.correspondent_account,
        inn: response.payload.jurisdiction?.court_federal_treasury?.inn,
        budgetCode: response.payload.jurisdiction?.court_federal_treasury?.kbk,
        oktmo: response.payload.jurisdiction?.court_federal_treasury?.oktmo,
        ifnsCode: response.payload.jurisdiction?.court_federal_treasury?.ifns,
        ifnsPhones:
          response.payload.jurisdiction?.court_federal_treasury?.ifns_phones,
        ifnsSite:
          response.payload.jurisdiction?.court_federal_treasury?.website_url,
      },
      fssp: {
        code: response.payload.jurisdiction?.bailiffs_department?.code,
        name: response.payload.jurisdiction?.bailiffs_department?.name,
        phones: response.payload.jurisdiction?.bailiffs_department?.phones,
        email: response.payload.jurisdiction?.bailiffs_department?.emails,
        fullAddress:
          response.payload.jurisdiction?.bailiffs_department?.address
            ?.full_address,
        postcode:
          response.payload.jurisdiction?.bailiffs_department?.address
            ?.post_code,
        region:
          response.payload.jurisdiction?.bailiffs_department?.address?.region,
        city: response.payload.jurisdiction?.bailiffs_department?.address
          ?.locality,
        street:
          response.payload.jurisdiction?.bailiffs_department?.address?.street,
        house:
          response.payload.jurisdiction?.bailiffs_department?.address?.house,
      },
      higherCourtInfo: {
        name: response.payload.jurisdiction?.higher_court?.name,
        code: response.payload.jurisdiction?.higher_court?.code,
        email: response.payload.jurisdiction?.higher_court?.emails,
        phones: response.payload.jurisdiction?.higher_court?.phones,
        site: response.payload.jurisdiction?.higher_court?.website_url,
        fullAddress:
          response.payload.jurisdiction?.higher_court?.address?.full_address,
        postcode:
          response.payload.jurisdiction?.higher_court?.address?.post_code,
        region: response.payload.jurisdiction?.higher_court?.address?.region,
        city: response.payload.jurisdiction?.higher_court?.address?.locality,
        street: response.payload.jurisdiction?.higher_court?.address?.street,
        house: response.payload.jurisdiction?.higher_court?.address?.house,
      },
      higherCourtIfns: {
        postcode:
          response.payload.jurisdiction?.higher_court_federal_treasury?.address
            ?.post_code,
        region:
          response.payload.jurisdiction?.higher_court_federal_treasury?.address
            ?.region,
        city: response.payload.jurisdiction?.higher_court_federal_treasury
          ?.address?.locality,
        street:
          response.payload.jurisdiction?.higher_court_federal_treasury?.address
            ?.street,
        house:
          response.payload.jurisdiction?.higher_court_federal_treasury?.address
            ?.house,
      },
      higherCourtPaymentDetails: {
        receiver:
          response.payload.jurisdiction?.higher_court_federal_treasury?.name,
        bank: response.payload.jurisdiction?.higher_court_federal_treasury
          ?.bank_name,
        account:
          response.payload.jurisdiction?.higher_court_federal_treasury
            ?.bank_account,
        bik: response.payload.jurisdiction?.higher_court_federal_treasury?.bik,
        kpp: response.payload.jurisdiction?.higher_court_federal_treasury?.kpp,
        korAccount:
          response.payload.jurisdiction?.higher_court_federal_treasury
            ?.correspondent_account,
        inn: response.payload.jurisdiction?.higher_court_federal_treasury?.inn,
        budgetCode:
          response.payload.jurisdiction?.higher_court_federal_treasury?.kbk,
        oktmo:
          response.payload.jurisdiction?.higher_court_federal_treasury?.oktmo,
        ifnsCode:
          response.payload.jurisdiction?.higher_court_federal_treasury?.ifns,
        ifnsPhones:
          response.payload.jurisdiction?.higher_court_federal_treasury
            ?.ifns_phones,
        ifnsSite:
          response.payload.jurisdiction?.higher_court_federal_treasury
            ?.website_url,
      },
    };
  }

  /**
   * Сохраняет данные в подсудности.
   * @param id Идентификатор договора.
   * @param data Данные для отправки на сервер.
   */
  public async saveJurisdictionData(id: string, data: Record<string, any>) {
    const url = RouteHelper.compile(ApiEndpoint.EDIT_LOAN_JURISDICTION, { id });

    await this.client.patch(url, data);
  }

  /**
   * Загружает с сервера данные о файле для печати.
   * @param id Идентификатор договора.
   */
  public async fetchPrintableFileForContract(id: string): Promise<Print> {
    const url = RouteHelper.compile(ApiEndpoint.GET_CONTRACT_PRINTABLE_FILE, {
      id,
    });

    const response = await this.client.get(url);

    const exists = Boolean(response.payload.attachment);
    return {
      status: response.payload.status,
      isPrintableFileExists: exists,
      name: response.payload.attachment?.name ?? '',
      extension: response.payload.attachment?.extension ?? '',
      size: response.payload.attachment?.size
        ? Number(response.payload.attachment.size)
        : 0,
      downloadUrl: response.payload.attachment?.download_url ?? '',
      creationDate: response.payload.attachment?.created_at
        ? new Date(response.payload.attachment.created_at)
        : new Date(),
    };
  }

  /**
   * Загружает с сервера данные о задолженности.
   * @param id Идентификатор договора.
   */
  public async fetchContractDebt(id: string): Promise<Debt | undefined> {
    const url = RouteHelper.compile(ApiEndpoint.GET_LOAN_DEBT, {
      id,
    });
    let response: any;

    try {
      response = await this.client.get(url);
    } catch (error) {
      if (ApiHelper.isError(error, 404)) {
        return undefined;
      }

      throw error;
    }
    if (response.payload.debt_calculation === null) {
      return undefined;
    }

    return {
      status: response.payload.status,
      gosFee: response.payload.debt_calculation.state_fee_payment,
      totalAmount:
        response.payload.debt_calculation
          .amount_of_debt_calculation_and_state_fee_payment,
      debtAmount: response.payload.debt_calculation.amount_of_debt_calculation,
      debtBodyAmount:
        response.payload.debt_calculation.amount_of_debt_body_debt,
      interestCharge:
        response.payload.debt_calculation.amount_of_debt_accounted_interest,
      fee: response.payload.debt_calculation.amount_of_debt_penalty_fee_amount,
      penalty:
        response.payload.debt_calculation
          .amount_of_debt_sum_percent_per_pear_loan,
      doneDate: response.payload.debt_calculation.updated_at
        ? new Date(response.payload.debt_calculation.updated_at)
        : new Date(),
    };
  }

  /**
   * Запрашивает историю действий по договорам с сервера
   * @param pagingParams устанавливает параметры пагинации
   * @param filteringParams устанавливает параметры фильтрации
   * @param sortingParams устанавливает параметры сортировки
   */
  public async fetchHistory(
    pagingParams: PagingOptions,
    filteringParams: FilterParam[],
    sortingParams: SortingParams,
  ) {
    const { offset, limit } = pagingParams;
    const filterOptions = ApiHelper.remapFilterParams(filteringParams);
    const { field, order } = sortingParams;

    const response = await this.client.get(ApiEndpoint.GET_HISTORY, {
      offset,
      limit,
      ...filterOptions,
      [`order[${field}]`]: order,
    });

    const items: HistoryItem[] = response.payload.map((item: any) => {
      return {
        id: item.uuid,
        contractID: item.loan_uuid,
        number: item.loan_number,
        debtorFirstName: item.client.first_name,
        debtorLastName: item.client.last_name,
        debtorPatronymicName: item.client.patronymic,
        employeeFirstName: item.employee?.first_name,
        employeeLastName: item.employee?.last_name,
        employeePatronymicName: item.employee?.patronymic,
        loanDate: new Date(item.issue_date),
        updateDate: new Date(item.changed_at),
        prevStatus: item.status.previous,
        currentStatus: item.status.current,
      };
    });

    const count: number = response.meta.total_count;

    return {
      items,
      count,
    };
  }

  /**
   * Отправляет запрос на получение адресов БКИ.
   * @param id Идентификатор договора.
   */
  public async fetchAddressesKI(id: string): Promise<AddressBureau[]> {
    const url = RouteHelper.compile(ApiEndpoint.GET_ADDRESSES_KI, { id });
    const response = await this.client.post(url, {
      id,
    });

    return response.payload.addresses.map((address: any) => ({
      address: address.address ?? '',
      date: new Date(address.date),
      source: address.source,
      type: address.type,
    }));
  }

  /**
   * Получение информации по фоновой задаче.
   * @param id Идентификатор задачи.
   * Запуск фоновой задачи.
   * @param type Тип фоновой задачи.
   * @param payload Данные для запуска фоновой задачи.
   */
  public async startBackgroundTask(type: TaskType, payload?: any) {
    const url = RouteHelper.compile(ApiEndpoint.START_BACKGROUND_TASK, {
      type,
    });

    const response = await this.client.post(url, payload);

    return response.payload.task_uuid;
  }

  /**
   * Получить информации по фоновой задаче.
   * @param id Идентификатор задачи.
   */
  public async fetchBackgroundTask(id: string): Promise<Task> {
    const url = RouteHelper.compile(ApiEndpoint.GET_BACKGROUND_TASK, {
      id,
    });

    const response = await this.client.get(url);

    return {
      id: response.payload.uuid,
      status: response.payload.status,
      type: response.payload.type,
      dateStarted: new Date(response.payload.created_at),
      dateFinished: response.payload.finished_at
        ? new Date(response.payload.finished_at)
        : undefined,
      processedTotal: response.payload.progress.total,
      processedSuccess: response.payload.progress.success,
      processedError: response.payload.progress.error,
      result: {
        url: response.payload.payload?.download_url,
        contracts: Array.isArray(response.payload.payload)
          ? response.payload.payload.map((contract: any) => ({
              id: contract.uuid,
              status: contract.status,
              assigneeId: contract.target_employee_uuid || undefined,
            }))
          : undefined,
      },
    };
  }

  /**
   * Полчить список фоновых задач для пользователя.
   * @param id Идентификатор пользователя.
   * @param pagingParams Диапазон результатов.
   */
  public async fetchEmployeeTasks(id: string, pagingParams?: PagingOptions) {
    const url = RouteHelper.compile(ApiEndpoint.GET_EMPLOYEE_BACKGROUND_TASKS, {
      id,
    });
    let params = {};

    if (
      pagingParams &&
      Number.isInteger(pagingParams.offset) &&
      Number.isInteger(pagingParams.limit)
    ) {
      const { offset, limit } = pagingParams;

      params = {
        offset,
        limit,
      };
    }

    const response = await this.client.get(url, params);

    const items: TaskItem[] = response.payload.map((task: any) => ({
      id: task.uuid,
      status: task.status,
      type: task.type,
      dateStarted: new Date(task.created_at),
      dateFinished: task.finished_at ? new Date(task.finished_at) : undefined,
      processedTotal: task.progress.total,
      processedSuccess: task.progress.success,
      processedError: task.progress.error,
      assigneeId:
        task.payload?.find((item: any) => item.target_employee_uuid)
          ?.target_employee_uuid || undefined,
    }));

    const count: number = response.meta.total_count;

    return {
      items,
      count,
    };
  }

  /**
   * Приведение комментариев СУ к нашей структуре.
   */
  private mapCourtComments(comments: any): CourtComment[] {
    return (
      (comments as any[])?.reduce((acc, item: any) => {
        if (!item.text || !item.is_shared || !item.short_text) {
          return acc;
        }

        acc.push({
          uuid: item.uuid,
          textShort: item.short_text,
          text: item.text,
          shared: item.is_shared,
        });

        return acc;
      }, [] as CourtComment[]) ?? []
    );
  }

  /**
   * Получить список комментариев СУ.
   */
  public async fetchCourtComments(): Promise<CourtComment[]> {
    const url = RouteHelper.compile(ApiEndpoint.GET_JUDICIAL_SECTOR_COMMENTS);
    const response = await this.client.get(url);
    const comments = this.mapCourtComments(response.payload);

    return comments;
  }

  /**
   * Получить список регионов СУ.
   */
  public async fetchCourtRegions(): Promise<CourtRegion[]> {
    const url = RouteHelper.compile(ApiEndpoint.GET_COURTS_REGIONS);
    const response = await this.client.get(url);

    return response.payload;
  }

  /**
   * Получить список юридических лиц.
   */
  public async fetchLegalEntity(): Promise<LegalEntity[]> {
    const url = RouteHelper.compile(ApiEndpoint.GET_LEGAL_ENTITY_LIST);
    const response = await this.client.get(url);

    return (response.payload as any[]).reduce((acc, item: any) => {
      acc.push({
        name: item.name,
        alias: item.alias,
      });

      return acc;
    }, [] as LegalEntity[]);
  }

  /**
   * Загружает список СУ.
   */
  public async fetchCourts(
    pagingParams: PagingOptions,
    filteringParams: FilterParam[],
  ) {
    const { offset, limit } = pagingParams;
    const filterOptions = ApiHelper.remapFilterParams(filteringParams);
    const url = RouteHelper.compile(ApiEndpoint.GET_COURTS_LIST);
    const params = {
      offset,
      limit,
      ...filterOptions,
    };
    const response = await this.client.get(url, params);

    const items: CourtEntity[] = response.payload.map((court: any) => {
      return {
        id: court.uuid,
        code: court.code,
        name: court.name,
        comments: this.mapCourtComments(court.comments),
        region: court.region,
      };
    });

    const count: number = response.meta.total_count;

    return {
      items,
      count,
    };
  }

  /**
   * Прикрепляет комментарий СУ.
   * @param courtID Идентификатор СУ.
   * @param commentID Данные для отправки на сервер.
   */
  public async attachCourtComment(courtId: string, commentId: string) {
    const url = RouteHelper.compile(ApiEndpoint.ATTACH_COURT_COMMENT, {
      courtId,
      commentId,
    });

    await this.client.put(url);
  }

  /**
   * Открепляет комментарий СУ.
   * @param courtID Идентификатор СУ.
   * @param commentID Данные для отправки на сервер.
   */
  public async detachCourtComment(courtId: string, commentId: string) {
    const url = RouteHelper.compile(ApiEndpoint.DETACH_COURT_COMMENT, {
      courtId,
      commentId,
    });

    await this.client.delete(url);
  }
}
